import { Box, Typography, FormLabel, Chip, Avatar, Link, Divider, Modal } from '@mui/material';

// import ProjectUsersTableCell from 'components/Table/ContentPlan/ProjectUsersTableCell';
import xcel from 'assets/images/xcel.png';
import EditIcon from '@mui/icons-material/Edit';
import { useState } from 'react';
import EditContentPlan from 'components/Form/ContentPlan/EditContentPlan';
import moment from 'moment';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import useCommonFunction from 'hooks/useCommonFunction';

export default function Summary(props) {
  const { data } = props;
  const { getIsFeatureEnabled } = useFeatureFlags();
  const { getNameInitials, getBackGroundColor } = useCommonFunction();
  const isEnabled = getIsFeatureEnabled('CONTENT_PLAN', data?.role);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box sx={{ padding: 3 }}>
      <Box sx={{ position: 'relative' }}>
        <FormLabel>Summary</FormLabel>
        {isEnabled && (
          <EditIcon
            sx={{ position: 'absolute', right: 0, cursor: 'pointer' }}
            onClick={handleOpen}
          />
        )}
      </Box>
      <Box sx={{ margin: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ width: '20%' }}>Documents: </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <Avatar src={xcel} alt="name" sx={{ width: 20, height: 20, mr: 1 }} variant="square" />
            <Link href={data.documentLink} target="_blank">
              document
            </Link>
          </Box>
        </Box>
      </Box>
      <Box sx={{ margin: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ width: '20%' }}>Topic: </Typography>
          <Typography>{data.topic} </Typography>
        </Box>
      </Box>
      <Box sx={{ margin: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ width: '20%' }}>Category: </Typography>
          <Typography>{data.category} </Typography>
        </Box>
      </Box>
      <Box sx={{ margin: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ width: '20%' }}>Deadline: </Typography>
          {data.deadline ? (
            <Chip label={moment.utc(data.deadline).format('DD-MM-YYYY')} color={'warning'} />
          ) : (
            '-'
          )}
        </Box>
      </Box>
      <Box sx={{ margin: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ width: '20%' }}>Developers: </Typography>
          {/* <ProjectUsersTableCell members={data.users} /> */}
          <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '80%' }}>
            {data.users.map((item, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar
                  src={item?.image}
                  alt="name"
                  sx={{
                    width: 20,
                    height: 20,
                    fontSize: '0.625rem',
                    background: getBackGroundColor(item?.name)
                  }}>
                  {getNameInitials(item?.name)}
                </Avatar>
                <Typography ml={1}>
                  {item?.name}
                  {index === data.users.length - 1 ? '' : ','} &nbsp;
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box sx={{ margin: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ width: '20%' }}>Allocated By: </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '80%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                src={data.allocatedByUser?.image}
                alt="name"
                sx={{
                  width: 20,
                  height: 20,
                  fontSize: '0.625rem',
                  background: getBackGroundColor(data.allocatedByUser?.name)
                }}>
                {getNameInitials(data.allocatedByUser?.name)}
              </Avatar>
              <Typography ml={1}>{data.allocatedByUser?.name}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ margin: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ width: '20%' }}>Status: </Typography>
          <Chip label={data.status} color={data.status === 'Completed' ? 'success' : 'warning'} />
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            borderRadius: 3,
            outline: 'none'
          }}>
          <Box sx={{ m: 3, textAlign: 'center', position: 'relative' }}>
            <Typography>Edit Summary</Typography>
            <Typography
              sx={{ position: 'absolute', right: 0, top: 0, fontWeight: 500, cursor: 'pointer' }}
              onClick={handleClose}>
              X
            </Typography>
          </Box>
          <Divider />
          <Box
            sx={{
              maxHeight: 550,
              overflowY: 'scroll',
              outline: 'none',
              '&::-webkit-scrollbar': {
                display: 'none'
              }
            }}>
            <EditContentPlan data={data} handleClose={handleClose} />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
