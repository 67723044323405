import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, Card, CardContent, Divider, Modal, Typography } from '@mui/material';
import EditProfile from 'components/Form/Profile/EditProfile';
import TechSkillsListCell from 'components/Table/Employee/List/TechSkillsListCell';
import useRequestToServer from 'services';
import _ from 'lodash';
import useSkills from 'hooks/useSkills';
import useCommonFunction from 'hooks/useCommonFunction';
import useNotification from 'hooks/useNotification';

const UserProfile = () => {
  const [usersDetails, setUserDetails] = useState(null);
  const { requestToServer } = useRequestToServer();
  const { getNameInitials, getBackGroundColor } = useCommonFunction();
  const { skills } = useSkills();
  const { successMessage, errorMessage } = useNotification();
  const [open, setOpen] = useState(false);
  const [skillList, setSkillList] = useState([]);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getUserProfile = async () => {
    let response = await requestToServer('user/profile', '', 'get', true);
    if (response && response.status === 200) {
      let { data } = response.data;
      setUserDetails(data[0]);
    } else {
      errorMessage(response.data.message);
    }
  };

  const updateProfile = async (values) => {
    const skills = _.map(values?.skills, (id) => ({ id }));
    let dataToSend = _.omit(values, ['email', 'skills']);
    dataToSend.skills = skills;
    let response = await requestToServer('user/profile', dataToSend, 'put', true);
    if (response && response.status === 200) {
      successMessage('Profile updated successfully.');
      getUserProfile();
      handleClose();
    } else {
      errorMessage(response.data.message);
    }
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  useEffect(() => {
    setSkillList(skills);
  }, [skills]);

  return (
    <Card>
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '200px'
          }}>
          <Avatar
            sx={{
              width: '200px',
              height: '200px',
              fontSize: '3rem',
              background: getBackGroundColor(usersDetails?.name)
            }}
            src={usersDetails?.profile_url}
            aria-label="user-avatar">
            {getNameInitials(usersDetails?.name)}
          </Avatar>
        </Box>
        <Box
          sx={{
            margin: 3,
            display: 'flex',
            justifyContent: 'center'
          }}>
          <Box mt={3} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="subtitle1">Full Name</Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="subtitle1">Email Address</Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="subtitle1">Position</Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="subtitle1">Skills</Typography>
            </Box>
          </Box>
          <Box m={3} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="subtitle1">{usersDetails?.name} </Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="subtitle1">{usersDetails?.email}</Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="subtitle1">
                {usersDetails?.position?.name ? usersDetails?.position?.name : '-'}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="subtitle1">
                <TechSkillsListCell techSkills={usersDetails?.skills} />
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex' }} xs={12}>
          <Box mt={2}>
            <Button variant="contained" color="primary" onClick={handleOpen}>
              Update Profile
            </Button>
          </Box>
        </Box>
      </CardContent>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            borderRadius: 3,
            outline: 'none'
          }}>
          <Box sx={{ m: 3, textAlign: 'center', position: 'relative' }}>
            <Typography>Update Profile</Typography>
            <Typography
              sx={{ position: 'absolute', right: 0, top: 0, fontWeight: 500, cursor: 'pointer' }}
              onClick={handleClose}>
              X
            </Typography>
          </Box>
          <Divider />
          <Box
            sx={{
              maxHeight: 550,
              overflowY: 'scroll',
              outline: 'none',
              '&::-webkit-scrollbar': {
                display: 'none'
              }
            }}>
            <EditProfile data={usersDetails} handleSubmit={updateProfile} skillList={skillList} />
          </Box>
        </Box>
      </Modal>
    </Card>
  );
};

export default UserProfile;
