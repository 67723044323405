import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from '@mui/material';
import logo from 'assets/images/full-logo.svg';
import { useNavigate } from 'react-router-dom';
import useRequestToServer from 'services';
import useNotification from 'hooks/useNotification';

export default function ForgotPassword() {
  const { requestToServer } = useRequestToServer();
  const { successMessage, errorMessage } = useNotification();

  const navigate = useNavigate();

  const forgotPasswordValidationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required')
  });

  const fPFormik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: forgotPasswordValidationSchema,
    onSubmit: async (values) => {
      let response = await requestToServer('auth/forget-password', values, 'post');
      if (response && response.status === 200) {
        successMessage('Reset password link has been sent to your email.');
        navigate('/'); //navigate to login
      } else {
        errorMessage(response.data.message);
      }
    }
  });

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <img
          style={{ width: 180, objectFit: 'contain !important' }}
          src={logo}
          alt="techuz"
          loading="lazy"
        />
        <Typography component="h1" variant="h5">
          Forgot password
        </Typography>
        <Box component="form" onSubmit={fPFormik.handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={fPFormik.values.email}
            onChange={fPFormik.handleChange}
            error={fPFormik.touched.email && Boolean(fPFormik.errors.email)}
            helperText={fPFormik.touched.email && fPFormik.errors.email}
          />

          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Submit
          </Button>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <Link onClick={() => navigate('/')}>Sign in</Link>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
