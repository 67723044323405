import * as CryptoJS from 'crypto-js';
import { letterColors } from 'config/color';

const useCommonFunction = () => {
  const secureKey = process.env.REACT_APP_CYRPTO_SECURE_KEY;
  const encrypt = (data) => {
    return CryptoJS.AES.encrypt(`${data}`, `${secureKey}`).toString();
  };

  const decrypt = (data) => {
    const decryptedId = CryptoJS.AES.decrypt(data, `${secureKey}`);
    return decryptedId.toString(CryptoJS.enc.Utf8);
  };

  const getLables = (data, value) => {
    let result = data.find((_data) => _data.id === value);
    //Below ternary condition is only for now as data is static, will update once API availbale
    return result?.name ? result?.name : result?.project_name;
  };

  const getNameInitials = (name = '#') => {
    return name
      ?.replace(/[^a-zA-Z- ]/g, '')
      .match(/\b\w/g)
      ?.join('')
      .toUpperCase();
  };

  const checkUpcomingDeadLine = (date) => {
    if (date) {
      const start = new Date();
      const end = new Date(date);
      const diffInTime = end.getTime() - start.getTime();
      const diffInDays = diffInTime / (1000 * 3600 * 24);
      const diff = Math.floor(diffInDays);
      if (diff <= 7) {
        return 'warning';
      }
      return 'success';
    } else {
      return 'warning';
    }
  };

  const getBackGroundColor = (name = 'A') => {
    const firstLetter = name.charAt(0).toUpperCase();
    return letterColors[firstLetter];
  };

  return {
    getLables,
    encrypt,
    decrypt,
    getNameInitials,
    checkUpcomingDeadLine,
    getBackGroundColor
  };
};

export default useCommonFunction;
