import { Routes, Route } from 'react-router-dom';
import { UserProfile } from './index';
import ChangePassword from './ChangePassword';

function Index() {
  return (
    <Routes>
      <Route path="/" element={<UserProfile />} />
      <Route path="/change-password" element={<ChangePassword />} />
    </Routes>
  );
}

export default Index;
