import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useAuth } from 'providers/AuthProvider';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IconButton, InputAdornment, Link } from '@mui/material';
import logo from 'assets/images/full-logo.svg';
import { useNavigate } from 'react-router-dom';
import useRequestToServer from 'services';
import useCommonFunction from 'hooks/useCommonFunction';
import useNotification from 'hooks/useNotification';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function SignIn() {
  const { login, rememberMe } = useAuth();
  const { encrypt } = useCommonFunction();
  const { requestToServer } = useRequestToServer();
  const { errorMessage } = useNotification();
  const [showPassword, setShowPassword] = React.useState(false);

  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().required('Password is required'),
    remember: Yup.boolean()
  });

  const formik = useFormik({
    initialValues: {
      email: rememberMe ? rememberMe : '',
      password: '',
      remember: rememberMe ? true : false
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let dataToSend = {
        email: values.email,
        password: encrypt(values.password)
      };
      let { remember } = values; // eslint-disable-line
      let response = await requestToServer('auth/login', dataToSend, 'post');
      if (response && response.status === 200) {
        let { data } = response.data;
        let addOn = { remember: remember };
        let userData = { ...data, ...addOn };
        login(userData);
      } else {
        errorMessage(response.data.message);
      }
    }
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <img
          style={{ width: 180, objectFit: 'contain !important', marginBottom: 50 }}
          src={logo}
          alt="techuz"
          loading="lazy"
        />
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            id="password"
            autoComplete="current-password"
            value={formik.values.password}
            onChange={formik.handleChange}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <FormControlLabel
              control={
                <Checkbox
                  name="remember"
                  checked={formik.values.remember}
                  value={formik.values.remember}
                  onChange={formik.handleChange}
                  color="primary"
                />
              }
              label="Remember me"
            />
            <Link onClick={() => navigate('/forgot-password')}>Forgot password?</Link>
          </Box>
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Sign In
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
