import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IconButton, InputAdornment, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from 'assets/images/full-logo.svg';
import useRequestToServer from 'services';
import { useParams } from 'react-router-dom';
import useCommonFunction from 'hooks/useCommonFunction';
import useNotification from 'hooks/useNotification';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function ResetPassword() {
  const { requestToServer } = useRequestToServer();
  const { encrypt } = useCommonFunction();
  const { successMessage, errorMessage } = useNotification();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const navigate = useNavigate();
  const params = useParams();
  const { token } = params;

  const resetPasswordValidationSchema = Yup.object().shape({
    password: Yup.string()
      .required('New password is required')
      .min(6, 'Password must be longer than 6 characters')
      .max(99, 'Password must be shorter than 100 characters'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Password do not match')
      .required('Confirm password is required')
  });

  const rPFormik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: resetPasswordValidationSchema,
    onSubmit: async (values) => {
      let dataToSend = {
        password: encrypt(values.password),
        confirmPassword: encrypt(values.confirmPassword)
      };
      let response = await requestToServer(`auth/reset-password/${token}`, dataToSend, 'put');
      if (response && response.status === 200) {
        successMessage('Password has been reset successfully.');
        navigate('/'); //navigate to login
      } else {
        errorMessage(response.data.message);
      }
    }
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <img
          style={{ width: 180, objectFit: 'contain !important' }}
          src={logo}
          alt="techuz"
          loading="lazy"
        />
        <Typography component="h1" variant="h5">
          Reset password
        </Typography>
        <Box component="form" onSubmit={rPFormik.handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Enter new password"
            id="enter-new-password"
            autoComplete="current-password"
            value={rPFormik.values.password}
            onChange={rPFormik.handleChange}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={rPFormik.touched.password && Boolean(rPFormik.errors.password)}
            helperText={rPFormik.touched.password && rPFormik.errors.password}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm new password"
            id="confirm-new-password"
            autoComplete="current-password"
            value={rPFormik.values.confirmPassword}
            onChange={rPFormik.handleChange}
            type={showConfirmPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    edge="end">
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={rPFormik.touched.confirmPassword && Boolean(rPFormik.errors.confirmPassword)}
            helperText={rPFormik.touched.confirmPassword && rPFormik.errors.confirmPassword}
          />

          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Submit
          </Button>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <Link onClick={() => navigate('/')}>Sign in</Link>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
