import React, { useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import AccountCircle from '@mui/icons-material/AccountCircle';
import { useAuth } from 'providers/AuthProvider';
import { useNavigate, useLocation } from 'react-router-dom';
import { Avatar, Menu, MenuItem, Tooltip } from '@mui/material';

import contentPlanIcon from 'assets/icons/content-plan.png';
import employeeListIcon from 'assets/icons/employee-list.png';
import gdStandupsIcon from 'assets/icons/global-daily-standups.png';
import ProjectExperimentIcon from 'assets/icons/project-experiment.png';
import projectListIcon from 'assets/icons/project-list.png';
import logo from 'assets/images/full-logo.svg';
import { ADMINS } from 'config/constant';
import useCommonFunction from 'hooks/useCommonFunction';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

export default function MiniDrawer({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { getNameInitials, getBackGroundColor } = useCommonFunction();
  const [menuItem, setMenuItem] = useState([]);

  const paths = useMemo(
    () => [
      {
        name: 'Project list',
        path: '/projects',
        icon: projectListIcon,
        isUserMenu: true
      },
      {
        name: 'Employee list',
        path: '/employees',
        icon: employeeListIcon,
        isUserMenu: false
      },
      {
        name: 'Global Daily standups',
        path: '/global-daily-standups',
        icon: gdStandupsIcon,
        isUserMenu: true
      },
      {
        name: 'Content plan',
        path: '/content-plan',
        icon: contentPlanIcon,
        isUserMenu: true
      },
      {
        name: 'Project experiment',
        path: '/project-experiment',
        icon: ProjectExperimentIcon,
        isUserMenu: true
      }
    ],
    []
  );
  const settings = ['Profile', 'Change password', 'Logout'];

  const { logout, user } = useAuth();
  const [open, setOpen] = useState(true);
  const [currentPage, setCurrentPage] = useState(paths[0].name);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (location) {
      const pageName = paths.filter((item) => item.path === location.pathname);
      if (pageName.length > 0) {
        setCurrentPage(pageName[0].name);
      }
    }
  }, [location, paths]);

  useEffect(() => {
    const role = user?.loginDetails?.role ? user?.loginDetails?.role : 'User';

    if (!ADMINS.includes(role)) {
      const getUserMenu = paths.filter((item) => item.isUserMenu === true);
      setMenuItem(getUserMenu);
    } else {
      setMenuItem(paths);
    }
  }, [user]);

  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleUsersMenuItem = (setting) => {
    handleCloseUserMenu();
    switch (setting) {
      case 'Profile':
        setCurrentPage('Profile');
        navigate('/profile');
        return;
      case 'Change password':
        setCurrentPage('Change password');
        navigate('/profile/change-password');
        return;
      case 'Logout':
        logout();
        return;

      default:
        return;
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', alignItems: 'center' } }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' })
              }}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              {currentPage}
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  sx={{
                    background: getBackGroundColor(user?.loginDetails?.name)
                  }}
                  alt={user?.loginDetails?.name}
                  src={user?.loginDetails?.name}>
                  {getNameInitials(user?.loginDetails?.name)}
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}>
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={() => handleUsersMenuItem(setting)}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Box sx={{ width: 180, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img
              style={{ width: 140, height: 40, objectFit: 'contain !important' }}
              src={logo}
              alt="techuz"
              loading="lazy"
            />
          </Box>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItem.map((path) => (
            <ListItem
              key={path.name}
              disablePadding
              sx={{
                display: 'block',
                backgroundColor: path.name === currentPage ? 'rgba(0,0,0,0.20)' : 'transparent'
              }}
              onClick={() => {
                setCurrentPage(path.name);
                navigate(path.path);
              }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5
                }}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center'
                  }}>
                  <Avatar
                    sx={{
                      width: 24,
                      height: 24
                    }}
                    variant="square"
                    src={path.icon}
                    alt={path.name}
                  />
                </ListItemIcon>
                <ListItemText primary={path.name} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        {/* <Divider />
        <List sx={{ position: 'absolute', bottom: 0 }} onClick={() => logout()}>
          <ListItem key={'Logout'} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5
              }}>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center'
                }}>
                <AccountCircle />
              </ListItemIcon>
              <ListItemText primary={'Logout'} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List> */}
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}
