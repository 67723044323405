import React, { useEffect, useRef, useState } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { Button, Divider, MenuItem, Paper, Select } from '@mui/material';
import useRequestToServer from 'services';
import useNotification from 'hooks/useNotification';
import moment from 'moment';
import useAllGlobalProjects from 'hooks/useAllGlobalProjects';

export default function GlobalDailyStandups() {
  const { requestToServer } = useRequestToServer();
  const { errorMessage } = useNotification();
  const bottomElement = useRef(null);
  const { projects } = useAllGlobalProjects();
  const [selectedProject, setSelectedProject] = useState(0);
  const [dailyStanups, setDailyStanups] = useState([]);
  const [isTruncated, setIsTruncated] = useState();

  const getGlobalStandupMeetingList = async () => {
    let response = await requestToServer(
      `global-standup-meetings?projectId=${selectedProject?.id || 0}&isExperiment=${
        selectedProject?.isExperiment ? 1 : 0
      }`,
      '',
      'get',
      true
    );
    if (response && response.status === 200) {
      let { data } = response.data;
      setDailyStanups(data.result);
      setIsTruncated(Array(data?.result?.length).fill(true));
    } else {
      errorMessage(response.data.message);
    }
  };

  useEffect(() => {
    getGlobalStandupMeetingList();
  }, [selectedProject]);

  useEffect(() => {
    setTimeout(() => {
      bottomElement.current.scrollIntoView({
        behavior: 'smooth'
      });
    }, 10);
  }, [dailyStanups]);

  const toggleTruncate = (index) => {
    const newTruncationState = [...isTruncated];
    newTruncationState[index] = !newTruncationState[index];
    setIsTruncated(newTruncationState);
  };

  return (
    <Box>
      <Paper elevation={4} sx={{ mt: 2, padding: 2 }}>
        <Box>
          <Select
            sx={{ width: 250 }}
            disableUnderline
            value={selectedProject}
            variant="filled"
            onChange={(e) => setSelectedProject(e.target.value)}
            inputProps={{
              sx: {
                padding: '8px 16px'
              }
            }}>
            <MenuItem value="0">
              <Box
                sx={{
                  width: '200px',
                  display: 'block',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}>
                <Typography ml={1}>All</Typography>
              </Box>
            </MenuItem>
            {projects.map((option, index) => (
              <MenuItem key={index} value={option}>
                <Box
                  sx={{
                    width: '200px',
                    display: 'block',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}>
                  <Typography ml={1}>{option?.name}</Typography>
                </Box>
              </MenuItem>
            ))}
          </Select>
          <Divider />

          <Timeline
            position="alternate"
            sx={{
              maxHeight: 560,
              overflowY: 'scroll'
            }}>
            {dailyStanups.length > 0 ? (
              dailyStanups.map((item, index) => (
                <TimelineItem key={index}>
                  <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    align={index % 2 === 0 ? 'right' : 'left'}
                    variant="body2"
                    color="text.secondary">
                    <Typography>{moment.utc(item.date).format('hh:mm')}</Typography>
                    <Typography>
                      {item?.project?.name}&nbsp;{item?.isExperiment ? '(Experiment)' : ''}
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot>
                      <LaptopMacIcon />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Paper
                      elevation={3}
                      sx={{
                        padding: '6px 16px',
                        background: item?.isExperiment ? '#9ae4ff' : '#fff'
                      }}>
                      <Typography variant="caption" component="span">
                        {item?.createdByUser?.name}
                      </Typography>
                      <Typography
                        sx={{
                          textAlign: 'left',
                          maxWidth: 500,
                          wordBreak: 'break-word',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          '-webkit-line-clamp': isTruncated[index] ? '4' : 'unset',
                          '-webkit-box-orient': 'vertical'
                        }}
                        dangerouslySetInnerHTML={{ __html: item?.description }}
                      />
                      {isTruncated[index] ? (
                        <Button
                          sx={{ minWidth: 30, padding: 0 }}
                          onClick={() => toggleTruncate(index)}>
                          More
                        </Button>
                      ) : (
                        <Button
                          sx={{ minWidth: 30, padding: 0 }}
                          onClick={() => toggleTruncate(index)}>
                          Less
                        </Button>
                      )}
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              ))
            ) : (
              <Typography align="center">No record found</Typography>
            )}
            <div className="bottom" ref={bottomElement} />
          </Timeline>
        </Box>
      </Paper>
    </Box>
  );
}
