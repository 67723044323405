import { Box, Avatar, Typography } from '@mui/material';
import useCommonFunction from 'hooks/useCommonFunction';

const EmployeeNameCell = (props) => {
  const { employee } = props;
  const { getNameInitials, getBackGroundColor } = useCommonFunction();
  if (!employee) {
    return;
  }
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          src={employee?.avatar}
          alt="name"
          size="xs"
          sx={{
            border: '2px solid #FFF',
            cursor: 'pointer',
            position: 'relative',
            mr: 1,
            background: getBackGroundColor(employee.name)
          }}>
          {getNameInitials(employee.name)}
        </Avatar>
        <Typography>{employee.name}</Typography>
      </Box>
    </Box>
  );
};

export default EmployeeNameCell;
