import React, { useEffect } from 'react';
import MuiAlert from '@mui/material/Alert';
import { useNotificationDispatch, Type } from 'providers/NotificationProvider';
import { Box } from '@mui/material';

const Alert = React.forwardRef(function Alert(props, ref) {
  const dispatch = useNotificationDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch({
        type: Type.REMOVE_MESSAGE,
        payload: props.id
      });
    }, 4000);
  }, []);

  return (
    <Box sx={{ backgroundColor: props.property.bgColor, borderRadius: '4px' }}>
      <MuiAlert
        sx={{ border: 'none !important', borderRadius: 0 }}
        elevation={6}
        ref={ref}
        variant="standard"
        {...props}
      />
    </Box>
  );
});
export default Alert;
