import { Routes, Route } from 'react-router-dom';

import { ProtectedRoute } from 'components/ProtectedRoute';
import { AuthProvider } from 'providers/AuthProvider';
import { FeatureFlagsProvider } from 'providers/FeatureFlagsProvider';
import { NotificationProvider } from 'providers/NotificationProvider';
import { LoaderProvider } from 'providers/LoaderProvider';

import Auth from 'pages/Auth/routes';
import Profile from 'pages/Profile/routes';
import GlobalDailyStandups from 'pages/GlobalDailyStandups/routes';
import ProjectList from 'pages/Projects/routes';
import EmployeeList from 'pages/Employee/routes';
import ContentPlan from 'pages/ContentPlan/routes';
import ProjectExperimentList from 'pages/ProjectExperiment/routes';
import SnackBar from 'components/SnackBar';
import GlobalLoader from 'components/Loader';
function App() {
  return (
    <NotificationProvider>
      <AuthProvider>
        <LoaderProvider>
          <FeatureFlagsProvider>
            <SnackBar />
            <GlobalLoader />

            <Routes>
              <Route path="/*" element={<Auth />} />
              <Route
                path="/profile/*"
                element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/projects/*"
                element={
                  <ProtectedRoute>
                    <ProjectList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/employees/*"
                element={
                  <ProtectedRoute>
                    <EmployeeList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/global-daily-standups/*"
                element={
                  <ProtectedRoute>
                    <GlobalDailyStandups />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/content-plan/*"
                element={
                  <ProtectedRoute>
                    <ContentPlan />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/project-experiment/*"
                element={
                  <ProtectedRoute>
                    <ProjectExperimentList />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </FeatureFlagsProvider>
        </LoaderProvider>
      </AuthProvider>
    </NotificationProvider>
  );
}
export default App;
