import {
  useNotificationDispatch,
  Type as NotifType,
  Variant
} from 'providers/NotificationProvider';

const useNotification = () => {
  const notifDispatch = useNotificationDispatch();

  const successMessage = (message) => {
    notifDispatch({
      type: NotifType.ADD_MESSAGE,
      payload: {
        message: message,
        variant: Variant.SUCCESS
      }
    });
  };

  const errorMessage = (message) => {
    notifDispatch({
      type: NotifType.ADD_MESSAGE,
      payload: {
        message: message,
        variant: Variant.ERROR
      }
    });
  };

  return { successMessage, errorMessage };
};

export default useNotification;
