import { Box, ButtonGroup, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Confirm from 'components/Confirm';

const ActionTableCell = (props) => {
  const { handleDelete, row } = props;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
      <ButtonGroup variant="text" aria-label="text button group">
        <Confirm
          message="Are you sure you want to delete?"
          onConfirm={() => {
            handleDelete(row.id);
          }}>
          {({ open }) => (
            <Button onClick={open}>
              <DeleteIcon color="error" />
            </Button>
          )}
        </Confirm>
      </ButtonGroup>
    </Box>
  );
};

export default ActionTableCell;
