import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { Avatar } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { InputAdornment, TableSortLabel, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import xcel from 'assets/images/xcel.png';
import Link from '@mui/material/Link';
import ProjectUsersTableCell from './ProjectUsersTableCell';
import moment from 'moment';

import useRequestToServer from 'services';
import useNotification from 'hooks/useNotification';
import usePagination from 'hooks/usePagination';
import useDebounce from 'hooks/useDebounce';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { useAuth } from 'providers/AuthProvider';
import ActionTableCell from './ActionTableCell';

function Row(props) {
  const { row, handleDelete, isEnabled } = props;
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <TableRow>
        <TableCell align="left" component="th" scope="row">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start'
            }}>
            <Avatar src={xcel} alt="name" sx={{ width: 20, height: 20, mr: 1 }} variant="square" />
            <Link href={row.documentLink} target="_blank">
              document
            </Link>
          </Box>
        </TableCell>
        <TableCell
          align="left"
          component="th"
          scope="row"
          sx={{
            cursor: 'pointer',
            '&:hover': {
              color: '#1976d2'
            }
          }}
          onClick={() => navigate(`/content-plan/detail/${row.id}`)}>
          {row.topic}
        </TableCell>
        <TableCell align="left">{row.category}</TableCell>
        <TableCell align="left">
          {row.deadline ? (
            <Chip label={moment.utc(row.deadline).format('DD-MM-YYYY')} color={'warning'} />
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell align="left">
          <ProjectUsersTableCell members={row.users} />
        </TableCell>
        <TableCell align="left">
          <ProjectUsersTableCell members={[row.allocatedByUser]} />
        </TableCell>
        <TableCell align="left">
          <Chip label={row.status} color={row.status === 'Completed' ? 'success' : 'warning'} />
        </TableCell>
        {isEnabled && (
          <TableCell align="left">
            <ActionTableCell row={row} handleDelete={handleDelete} />
          </TableCell>
        )}
      </TableRow>
    </React.Fragment>
  );
}

const headCells = [
  {
    id: 'name',
    filterable: false,
    label: 'Document link'
  },
  {
    id: 'topic',
    filterable: true,
    label: 'Topic'
  },
  {
    id: 'category',
    filterable: true,
    label: 'Category'
  },
  {
    id: 'deadline',
    filterable: true,
    label: 'Deadline'
  },
  {
    id: 'user',
    filterable: false,
    label: 'Users'
  },
  {
    id: 'allocated_by',
    filterable: false,
    label: 'Allocated By'
  },
  {
    id: 'status',
    filterable: true,
    label: 'Status'
  },
  {
    id: 'action',
    filterable: false,
    label: 'Action'
  }
];

const DEFAULT_ORDER = 'asc';
const DEFAULT_ORDER_BY = 'topic';

export default function Index() {
  const { requestToServer } = useRequestToServer();
  const { errorMessage, successMessage } = useNotification();
  const { getIsFeatureEnabled } = useFeatureFlags();
  const { user } = useAuth();
  const {
    currentPage,
    rowsPerPage,
    order,
    orderBy,
    handleChangePage,
    handleChangeRowsPerPage,
    createSortHandler
  } = usePagination(DEFAULT_ORDER, DEFAULT_ORDER_BY);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [globalSearch, setGlobalSearch] = useState('');

  const [totalRecords, setTotalRecords] = useState(0);
  const isEnabled = getIsFeatureEnabled('CONTENT_PLAN', user?.loginDetails?.role);

  const handleRowsPerPageChange = (event) => {
    setPage(0);
    handleChangeRowsPerPage(event);
  };

  const getContentPlanList = async (searchKeywords) => {
    let response = await requestToServer(
      `content-plan?recordPerPage=${rowsPerPage}&pageNumber=${currentPage}&orderBy=${orderBy}&orderDir=${order.toUpperCase()}${
        searchKeywords ? '&globalSearch=' + searchKeywords : ''
      }`,
      '',
      'get',
      true
    );
    if (response && response.status === 200) {
      let { data } = response.data;
      setRows(data.result);
      setPage(data.currentPage - 1);
      setTotalRecords(data.totalRecords);
    } else {
      errorMessage(response.data.message);
    }
  };

  useEffect(() => {
    getContentPlanList(globalSearch);
  }, [currentPage, rowsPerPage, order, orderBy]);

  const debounceFn = useDebounce(getContentPlanList, 500);

  const handleChange = (event) => {
    setGlobalSearch(event.target.value);
    debounceFn(event.target.value);
  };

  const handleDelete = async (id) => {
    let response = await requestToServer(`content-plan/${id}`, '', 'delete', true);
    if (response && response.status === 200) {
      successMessage('Deleted successfully.');
      getContentPlanList();
    } else {
      errorMessage(response.data.message);
    }
  };

  return (
    <>
      <Box>
        <TextField
          sx={{ mt: 0 }}
          variant="outlined"
          margin="normal"
          name="search"
          placeholder="Search..."
          value={globalSearch}
          onChange={handleChange}
          InputProps={{
            sx: { height: 40, width: 400 },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      </Box>
      <TableContainer component={Paper} elevation={4}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => {
                if (!isEnabled && headCell.id === 'action') {
                  return;
                }
                return (
                  <TableCell
                    key={headCell.id}
                    align="left"
                    sortDirection={orderBy === headCell.id ? order : false}>
                    {headCell.filterable ? (
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}>
                        {headCell.label}
                      </TableSortLabel>
                    ) : (
                      headCell.label
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 ? (
              rows.map((row, index) => (
                <Row key={index} isEnabled={isEnabled} row={row} handleDelete={handleDelete} />
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={7}>
                  No record found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 75, 100]}
        component="div"
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </>
  );
}
