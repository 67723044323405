import { useState, useEffect } from 'react';
import useRequestToServer from 'services';

function usePositions() {
  const { requestToServer } = useRequestToServer();
  const [positions, setPositions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await requestToServer('position/all', '', 'get', true);
        if (response && response.status === 200) {
          let { data } = response.data;
          setPositions(data?.result);
        } else {
          setPositions([]);
        }
      } catch (error) {
        setPositions([]);
      }
    };

    fetchData();
  }, []);

  return { positions };
}

export default usePositions;
