import { Box, Button, Divider, FormLabel, Modal, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import MilestonesTable from 'components/Table/Project/Milestones';
import CreateMilestone from 'components/Form/Project/CreateMilestone';
import AddIcon from '@mui/icons-material/Add';
import useRequestToServer from 'services';
import useNotification from 'hooks/useNotification';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';

export default function Milestones(props) {
  const { data } = props;
  const id = data.id;
  const { requestToServer } = useRequestToServer();

  const { successMessage, errorMessage } = useNotification();
  const { getIsFeatureEnabled, getProjectRole } = useFeatureFlags();
  const [openForm, setOpenForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [row, setRow] = useState();
  const [milestone, setMilestone] = useState();
  const [page] = useState(0);
  const [rowsPerPage] = useState(10);
  const role = getProjectRole(data);
  const isEnabled = getIsFeatureEnabled('MILESTONE', role);

  const handleOpen = () => {
    setOpenForm(true);
    setIsEdit(false);
    setRow();
  };
  const handleClose = () => {
    setOpenForm(false);
  };

  const getMilestones = async () => {
    let response = await requestToServer(`project/milestone/${id}`, '', 'get', true);
    if (response && response.status === 200) {
      let { data } = response.data;
      setMilestone(data);
    } else {
      errorMessage(response.data.message);
    }
  };

  //Create and Edit milestone
  const createMilestone = async (values) => {
    let url = `project/milestone/${id}`;
    let successMsg = 'Milestone created successfully.';
    let method = 'post';
    if (isEdit) {
      url = `project/milestone/${id}/${row.id}`;
      successMsg = 'Milestone updated successfully.';
      method = 'put';
    }
    let response = await requestToServer(url, values, method, true);
    if (response && (response.status === 201 || response.status === 200)) {
      successMessage(successMsg);
      handleClose();
      getMilestones();
    } else {
      errorMessage(response.data.message);
    }
  };

  const deleteMilestone = async (id) => {
    let response = await requestToServer(`project/milestone/${id}`, '', 'delete', true);
    if (response && response.status === 200) {
      successMessage('Milestone deleted successfully.');
      getMilestones();
    } else {
      errorMessage(response.data.message);
    }
  };

  useEffect(() => {
    getMilestones();
  }, [id, rowsPerPage, page]);

  return (
    <Box sx={{ margin: 3 }}>
      <FormLabel>Milestones</FormLabel>
      <Box sx={{ float: 'right', paddingBottom: 2 }} color="primary" px={2}>
        {!openForm && isEnabled && (
          <Button onClick={handleOpen} variant="contained" startIcon={<AddIcon />} color="primary">
            add new milestone
          </Button>
        )}
        <Modal
          open={openForm}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description">
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              borderRadius: 3,
              outline: 'none'
            }}>
            <Box sx={{ m: 3, textAlign: 'center', position: 'relative' }}>
              <Typography>{isEdit ? 'Edit Milestone' : 'Create Milestone'}</Typography>
              <Typography
                sx={{ position: 'absolute', right: 0, top: 0, fontWeight: 500, cursor: 'pointer' }}
                onClick={handleClose}>
                X
              </Typography>
            </Box>
            <Divider />
            <Box
              sx={{
                maxHeight: 550,
                overflowY: 'scroll',
                outline: 'none',
                '&::-webkit-scrollbar': {
                  display: 'none'
                }
              }}>
              <CreateMilestone row={row} handleSubmit={createMilestone} />
            </Box>
          </Box>
        </Modal>
      </Box>
      <MilestonesTable
        isEnabled={isEnabled}
        handleDelete={deleteMilestone}
        rows={milestone}
        page={page}
        rowsPerPage={rowsPerPage}
        shouldOpenEditForm={(row) => {
          setOpenForm(true);
          setIsEdit(true);
          setRow(row);
        }}
      />
    </Box>
  );
}
