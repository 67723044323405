import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { IconButton, InputAdornment, Card } from '@mui/material';
import useRequestToServer from 'services';
import useCommonFunction from 'hooks/useCommonFunction';
import useNotification from 'hooks/useNotification';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function ChangePassword() {
  const { requestToServer } = useRequestToServer();
  const { successMessage, errorMessage } = useNotification();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { encrypt } = useCommonFunction();
  const navigate = useNavigate();

  const ChangePasswordValidationSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Current password is required'),
    newPassword: Yup.string()
      .notOneOf(
        [Yup.ref('currentPassword'), null],
        'Current password and new password cannot be the same'
      )
      .required('New password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Password do not match')
      .required('Confirm password is required')
  });

  const cPFormik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    },
    validationSchema: ChangePasswordValidationSchema,
    onSubmit: async (values) => {
      let dataToSend = {
        currentPassword: encrypt(values.currentPassword),
        newPassword: encrypt(values.newPassword),
        confirmPassword: encrypt(values.confirmPassword)
      };
      let response = await requestToServer(`user/change-password`, dataToSend, 'put', true);
      if (response && response.status === 200) {
        successMessage('Password has been change successfully.');
      } else {
        errorMessage(response.data.message);
      }
    }
  });

  const handleClickShowCurrentPassword = () => setShowCurrentPassword((show) => !show);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  return (
    <Card sx={{ p: 3 }}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Typography component="h1" variant="h5">
            Change password
          </Typography>
          <Box component="form" onSubmit={cPFormik.handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="currentPassword"
              label="Enter current password"
              id="enter-current-password"
              autoComplete="current-password"
              value={cPFormik.values.currentPassword}
              onChange={cPFormik.handleChange}
              type={showCurrentPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowCurrentPassword}
                      edge="end">
                      {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={cPFormik.touched.currentPassword && Boolean(cPFormik.errors.currentPassword)}
              helperText={cPFormik.touched.currentPassword && cPFormik.errors.currentPassword}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="newPassword"
              label="Enter new password"
              id="enter-new-password"
              autoComplete="current-password"
              value={cPFormik.values.newPassword}
              onChange={cPFormik.handleChange}
              type={showNewPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewPassword}
                      edge="end">
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={cPFormik.touched.newPassword && Boolean(cPFormik.errors.newPassword)}
              helperText={cPFormik.touched.newPassword && cPFormik.errors.newPassword}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm new password"
              id="confirm-new-password"
              autoComplete="current-password"
              value={cPFormik.values.confirmPassword}
              onChange={cPFormik.handleChange}
              type={showConfirmPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      edge="end">
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={cPFormik.touched.confirmPassword && Boolean(cPFormik.errors.confirmPassword)}
              helperText={cPFormik.touched.confirmPassword && cPFormik.errors.confirmPassword}
            />
            <Box display="flex">
              <Button type="submit" fullWidth variant="contained" sx={{ m: 3 }}>
                Submit
              </Button>
              <Button
                onClick={() => navigate(-1)}
                fullWidth
                variant=""
                sx={{ bgcolor: 'rgba(0, 0, 0, 0.04)', m: 3 }}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </Card>
  );
}
