export const letterColors = {
  A: '#342D7E',
  B: '#342D7E',
  C: '#342D7E',
  D: '#342D7E',
  E: '#342D7E',
  F: '#342D7E',
  G: '#342D7E',
  H: '#342D7E',
  I: '#342D7E',
  J: '#342D7E',
  K: '#342D7E',
  L: '#342D7E',
  M: '#342D7E',
  N: '#342D7E',
  O: '#342D7E',
  P: '#342D7E',
  Q: '#342D7E',
  R: '#342D7E',
  S: '#342D7E',
  T: '#342D7E',
  U: '#342D7E',
  V: '#342D7E',
  W: '#342D7E',
  X: '#342D7E',
  Y: '#342D7E',
  Z: '#342D7E'
};
