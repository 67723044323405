import { Box, Button } from '@mui/material';
import React from 'react';

import EmployeeListTable from 'components/Table/Employee/List';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';

const EmployeeList = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box sx={{ float: 'right', paddingBottom: 2 }} color="primary" px={2}>
        <Button
          onClick={() => navigate('create')}
          variant="contained"
          startIcon={<AddIcon />}
          color="primary">
          add new employee
        </Button>
      </Box>

      <EmployeeListTable />
    </>
  );
};

export default EmployeeList;
