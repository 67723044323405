import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './TextEditor.css';

export default function TextEditor({ onUpdate, value, placeholder }) {
  const editorConfig = {
    toolbar: ['bold', 'italic', 'bulletedList'],
    placeholder: placeholder,
    maxHeight: 200,
    maxRows: 4
  };

  const handleChange = (data) => {
    onUpdate(data);
  };

  return (
    <CKEditor
      editor={ClassicEditor}
      config={editorConfig}
      data={value ?? ''}
      onChange={(event, editor) => {
        const data = editor.getData();
        handleChange(data);
      }}
    />
  );
}
