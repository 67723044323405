import React from 'react';
import {
  useNotificationsState,
  useNotificationDispatch,
  Type
} from 'providers/NotificationProvider';
import { Snackbar } from '@mui/material';
import Alert from './Alert';

const variant = {
  success: {
    color: '#2e7d32',
    bgColor: 'rgb(237, 247, 237)'
  },
  error: {
    color: '#d32f2f',
    bgColor: 'rgb(253, 237, 237)'
  },
  warning: {
    color: '#ed6c02',
    bgColor: 'rgb(255, 244, 229)'
  },
  info: {
    color: '#0288d1',
    bgColor: 'rgb(229, 246, 253)'
  }
};

const SnackBar = () => {
  const { notifications } = useNotificationsState();
  const dispatch = useNotificationDispatch();

  return (
    <>
      {notifications.map((item) => (
        <Snackbar
          key={item.id}
          open={true}
          sx={{ top: 140 }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          onClose={() => dispatch({ type: Type.REMOVE_MESSAGE, payload: item.id })}>
          <Alert
            id={item.id}
            property={variant[item.variant]}
            onClose={() => dispatch({ type: Type.REMOVE_MESSAGE, payload: item.id })}
            severity={item.variant}>
            {item.message}
          </Alert>
        </Snackbar>
      ))}
    </>
  );
};
export default SnackBar;
