import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Paper, Typography } from '@mui/material';
import ViewSummary from './View/Summary';
import { useLocation, useParams } from 'react-router-dom';

import useRequestToServer from 'services';
import useNotification from 'hooks/useNotification';

export default function ContentPlanDetail() {
  const { requestToServer } = useRequestToServer();
  const { errorMessage } = useNotification();
  const params = useParams();
  const { id } = params;
  const { state } = useLocation();
  const [row, setRow] = useState({});

  useEffect(() => {
    getContentPlanDetails(id);
  }, [id, state]);

  const getContentPlanDetails = async (id) => {
    let response = await requestToServer(`content-plan/${id}`, '', 'get', true);
    if (response && response.status === 200) {
      let { data } = response.data;
      setRow(data);
    } else {
      errorMessage(response.data.message);
    }
  };

  return (
    <Paper elevation={4}>
      {Object.keys(row).length > 0 ? (
        <Box sx={{ width: '100%' }}>
          <ViewSummary data={row} />
        </Box>
      ) : (
        <Box
          sx={{
            width: '100%',
            minHeight: 500,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <Typography>No record found!</Typography>
        </Box>
      )}
    </Paper>
  );
}
