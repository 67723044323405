import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
  FormHelperText
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import milestoneStatuses from 'StaticData/status.json';
import moment from 'moment';

export default function CreateMilestone(props) {
  const { row, handleSubmit } = props;
  const [forms, setForms] = useState([{ id: '', name: '', status: '' }]);
  const checkListStatus = milestoneStatuses.filter((_data) => _data.name != 'Inprogress');
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Milestone name is required'),
    startDate: Yup.date()
      .default(() => moment.utc().format('YYYY-MM-DD'))
      .required('Start date is required'),
    endDate: Yup.date()
      .when(
        'startDate',
        (startDate, schema) =>
          startDate && schema.min(startDate, 'End date must be greater than start date')
      )
      .required('End date is required'),
    status: Yup.string()
  });

  const formik = useFormik({
    initialValues: {
      name: row?.name || '',
      startDate: row?.startDate
        ? moment.utc(row?.startDate).format('YYYY-MM-DD')
        : moment.utc().format('YYYY-MM-DD'),
      endDate: row?.endDate ? moment.utc(row?.endDate).format('YYYY-MM-DD') : '',
      status: row?.status || 'Pending'
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (row) {
        values['checklists'] = forms;
      }
      handleSubmit(values);
    }
  });

  useEffect(() => {
    if (row && row?.checklists) {
      let items = row?.checklists.map((_data) => {
        delete _data.reviewedBy;
        return _data;
      });
      setForms(items);
    }
  }, [row]);

  const handleProjectAllocationChange = (index, event) => {
    const values = [...forms];
    values[index][event.target.name] = event.target.value;
    setForms(values);
  };
  return (
    <Box
      sx={{
        padding: 3,
        borderRadius: 2
      }}>
      <Box>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: 400 }
          }}
          onSubmit={formik.handleSubmit}
          autoComplete="off">
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              label="Milestone name"
              variant="filled"
              name="name"
              margin="normal"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              inputProps={{ maxLength: 50 }}
            />
            <Box>
              <TextField
                id="start-date"
                label="Start date"
                type="date"
                variant="filled"
                name="startDate"
                sx={{ width: '185px !important' }}
                value={formik.values.startDate}
                onKeyDown={(event) => {
                  event.preventDefault();
                }}
                onChange={formik.handleChange}
                error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                helperText={formik.touched.startDate && formik.errors.startDate}
                InputLabelProps={{
                  shrink: true
                }}
                // inputProps={{
                //   min: `${moment.utc().format('YYYY-MM-DD')}`
                // }}
              />
              <TextField
                id="end-date"
                label="End date"
                type="date"
                variant="filled"
                name="endDate"
                sx={{ width: '185px !important' }}
                value={formik.values.endDate}
                onKeyDown={(event) => {
                  event.preventDefault();
                }}
                onChange={formik.handleChange}
                error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                helperText={formik.touched.endDate && formik.errors.endDate}
                InputLabelProps={{
                  shrink: true
                }}
                // inputProps={{
                //   min: `${moment.utc(formik.values.startDate).format('YYYY-MM-DD')}`
                // }}
              />
            </Box>
            <TextField
              id="filled-select-currency"
              select
              label="Status"
              variant="filled"
              fullWidth
              name="status"
              value={formik.values.status}
              onChange={formik.handleChange}
              error={formik.touched.status && Boolean(formik.errors.status)}
              helperText={formik.touched.status && formik.errors.status}>
              {milestoneStatuses.map((option) => (
                <MenuItem key={option.name} value={option.value}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography ml={1}>{option?.name}</Typography>
                  </Box>
                </MenuItem>
              ))}
            </TextField>
            {row && (
              <FormControl sx={{ m: 1 }}>
                <InputLabel id="demo-multiple-chip-label">Checklists</InputLabel>
                <Box mt={5}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {forms.map((form, index) => (
                      <Box key={index}>
                        <Box sx={{ display: 'flex', alignItems: 'center', margin: 1 }}>
                          <Typography sx={{ width: '85%' }}>{form.name}</Typography>
                          <TextField
                            sx={{ width: '15%' }}
                            id="filled-select-currency"
                            select
                            label="Status"
                            variant="filled"
                            fullWidth
                            name="status"
                            value={form.status}
                            onChange={(event) => handleProjectAllocationChange(index, event)}>
                            {checkListStatus.map((option) => (
                              <MenuItem key={option.name} value={option.value}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <Typography ml={1}>{option?.name}</Typography>
                                </Box>
                              </MenuItem>
                            ))}
                          </TextField>
                        </Box>
                        <FormHelperText className="Mui-error">
                          {form.error && form.error}
                        </FormHelperText>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </FormControl>
            )}
          </Box>
          <Box sx={{ pr: '20px', pt: '20px', textAlign: 'right' }}>
            <Button type="submit" variant="outlined">
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
