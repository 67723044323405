import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { Avatar, Button, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import ClearIcon from '@mui/icons-material/Clear';
import Paper from '@mui/material/Paper';
import ProjectLeadTableCell from './ProjectLeadTableCell';
import moment from 'moment';
import xcel from 'assets/images/xcel.png';
import StatusTableCell from './StausTableCell';
import { Menu, MenuItem, IconButton, FormControl, InputLabel, Select } from '@mui/material';
import Link from '@mui/material/Link';
import useRequestToServer from 'services';
import useNotification from 'hooks/useNotification';
import useCommonFunction from 'hooks/useCommonFunction';
import usePagination from 'hooks/usePagination';
import { InputAdornment, TableSortLabel, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import useDebounce from 'hooks/useDebounce';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { loadLocalStorageState, saveLocalStorageState } from 'utils/localStorage';

function Row(props) {
  const { row, handleStatusChange } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuItemClick = (status) => {
    handleStatusChange(row.id, status);
    handleCloseMenu();
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const { checkUpcomingDeadLine } = useCommonFunction();
  return (
    <React.Fragment>
      <TableRow>
        <TableCell
          align="left"
          component="th"
          scope="row"
          onClick={() => navigate(`/projects/detail/${row.id}`)}>
          <Typography
            sx={{
              cursor: 'pointer',
              '&:hover': {
                color: '#1976d2'
              },
              width: '150px',
              display: 'block',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}>
            {row.name}
          </Typography>
        </TableCell>
        <TableCell align="left" component="th" scope="row">
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            {row?.documentLink ? (
              <>
                <Avatar
                  src={xcel}
                  alt="name"
                  sx={{ width: 20, height: 20, mr: 1 }}
                  variant="square"
                />
                <Link href={row?.documentLink} target="_blank">
                  {row.name}
                </Link>
              </>
            ) : (
              <b>-</b>
            )}
          </Box>
        </TableCell>
        <TableCell align="left">
          <ProjectLeadTableCell members={row.projectLead} />
        </TableCell>
        <TableCell align="left">
          <ProjectLeadTableCell members={row.developers} />
        </TableCell>
        <TableCell align="left">
          {row.upcomingDeadline ? (
            <Chip
              label={moment.utc(row.upcomingDeadline).format('DD-MM-YYYY')}
              color={checkUpcomingDeadLine(row.upcomingDeadline)}
            />
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell align="left">{row.type}</TableCell>
        <TableCell align="left">{<StatusTableCell statuses={row.detailStatus} />}</TableCell>
        <TableCell align="center">{row.status}</TableCell>
        <TableCell align="center">
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleOpenMenu}>
            <MoreVertIcon />
          </IconButton>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
            <MenuItem onClick={() => handleMenuItemClick('Completed')}>Mark As Completed</MenuItem>
            <MenuItem onClick={() => handleMenuItemClick('Archive')}>Mark As Archived</MenuItem>
          </Menu>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const headCells = [
  {
    id: 'name',
    filterable: true,
    label: 'Project name'
  },
  {
    id: 'documentLink',
    filterable: false,
    label: 'Plan link'
  },
  {
    id: 'projectLead',
    filterable: false,
    label: 'Project lead'
  },
  {
    id: 'users',
    filterable: false,
    label: 'Developers'
  },
  {
    id: 'date',
    filterable: false,
    label: 'Deadline'
  },
  {
    id: 'type',
    filterable: true,
    label: 'Type'
  },
  {
    id: 'status',
    filterable: false,
    label: 'Status'
  },
  {
    id: 'projectStatus',
    filterable: false,
    label: 'Project Status'
  },
  {
    id: 'action',
    filterable: false,
    label: 'Action'
  }
];

const DEFAULT_ORDER = 'asc';
const DEFAULT_ORDER_BY = 'name';
const DEFAULT_PROJECT_STATUS = 'Active';

export default function Index(props) {
  const { isEnabled } = props;
  const { requestToServer } = useRequestToServer();
  const { successMessage, errorMessage } = useNotification();
  const {
    currentPage,
    rowsPerPage,
    order,
    orderBy,
    handleChangePage,
    handleChangeRowsPerPage,
    createSortHandler
  } = usePagination(DEFAULT_ORDER, DEFAULT_ORDER_BY);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [globalSearch, setGlobalSearch] = useState(
    loadLocalStorageState('filters')?.globalSearch || ''
  );
  const [projectType, setProjectType] = useState(
    loadLocalStorageState('filters')?.projectType || ''
  );
  const [projectStatus, setProjectStatus] = useState(
    loadLocalStorageState('filters')?.projectStatus || DEFAULT_PROJECT_STATUS
  );
  const [status, setStatus] = useState(loadLocalStorageState('filters')?.status || '');
  const [totalRecords, setTotalRecords] = useState(0);

  const handleRowsPerPageChange = (event) => {
    setPage(0);
    handleChangeRowsPerPage(event);
  };

  const getProjectList = async (searchKeywords) => {
    let response = await requestToServer(
      `project?recordPerPage=${rowsPerPage}&pageNumber=${currentPage}&orderBy=${orderBy}&orderDir=${order.toUpperCase()}${
        searchKeywords ? '&globalSearch=' + searchKeywords : ''
      }&projectType=${projectType}&projectStatus=${projectStatus}&status=${status}`,
      '',
      'get',
      true
    );
    if (response && response.status === 200) {
      let { data } = response.data;
      setRows(data.result);
      setPage(data.currentPage - 1);
      setTotalRecords(data.totalRecords);
    } else {
      errorMessage(response.data.message);
    }
  };

  const handleProjectTypeChange = (event) => {
    setProjectType(event.target.value);
    saveLocalStorageState('filters', {
      projectType: event.target.value,
      projectStatus,
      status,
      globalSearch
    });
  };
  const handleProjectStatusChange = (event) => {
    setProjectStatus(event.target.value);
    saveLocalStorageState('filters', {
      projectType,
      projectStatus: event.target.value,
      status,
      globalSearch
    });
  };
  const handleStatus = (event) => {
    setStatus(event.target.value);
    saveLocalStorageState('filters', {
      projectType,
      projectStatus,
      status: event.target.value,
      globalSearch
    });
  };

  const handleDelete = async (id) => {
    let response = await requestToServer(`project/${id}`, '', 'delete', true);
    if (response && response.status === 200) {
      successMessage('Deleted successfully.');
      getProjectList();
    } else {
      errorMessage(response.data.message);
    }
  };

  const handleStatusChange = async (id, status) => {
    const requestBody = { status };
    let response = await requestToServer(`project/${id}`, requestBody, 'patch', true);
    if (response && response.status === 200) {
      successMessage('Update successfully.');
    } else {
      errorMessage(response.data.message);
    }
  };

  const clearFilters = () => {
    if (loadLocalStorageState('filters')) {
      handleChangePage(0);
      setGlobalSearch('');
      setProjectType('');
      setProjectStatus(DEFAULT_PROJECT_STATUS);
      setStatus('');
      getProjectList('');
    }
    saveLocalStorageState('filters', undefined);
  };

  useEffect(() => {
    getProjectList(globalSearch);
  }, [currentPage, rowsPerPage, order, orderBy, projectType, projectStatus, status]);

  const debounceFn = useDebounce(getProjectList, 500);

  const handleChange = (event) => {
    setGlobalSearch(event.target.value);
    debounceFn(event.target.value);
    saveLocalStorageState('filters', {
      projectType,
      projectStatus,
      status,
      globalSearch: event.target.value
    });
  };

  return (
    <>
      <Box>
        <TextField
          sx={{ mt: 0 }}
          variant="outlined"
          margin="normal"
          name="search"
          placeholder="Search..."
          value={globalSearch}
          onChange={handleChange}
          InputProps={{
            sx: { height: 40, width: 200 },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
        <FormControl sx={{ minWidth: 150, marginLeft: 1 }}>
          <InputLabel id="project-type-filter-label" sx={{ lineHeight: 1 }}>
            Project Type
          </InputLabel>
          <Select
            labelId="project-type-filter-label"
            id="project-type-filter-select"
            value={projectType}
            label="Project Type"
            onChange={handleProjectTypeChange}
            sx={{
              '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input': {
                paddingBottom: 1.1,
                paddingTop: 1.1
              }
            }}>
            <MenuItem value="">All</MenuItem>
            <MenuItem value="Fix">Fix</MenuItem>
            <MenuItem value={encodeURIComponent('T&M')}>T&M</MenuItem>
            <MenuItem value="Website">Website</MenuItem>
            <MenuItem value="Internal project">Internal project</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 150, marginLeft: 1 }}>
          <InputLabel id="project-type-filter-label" sx={{ lineHeight: 1 }}>
            Project Status
          </InputLabel>
          <Select
            labelId="project-type-filter-label"
            id="project-type-filter-select"
            value={projectStatus}
            label="Project Status"
            onChange={handleProjectStatusChange}
            sx={{
              '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input': {
                paddingBottom: 1.1,
                paddingTop: 1.1
              }
            }}>
            <MenuItem value="">All</MenuItem>
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
            <MenuItem value="Archive">Archived</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 150, marginLeft: 1 }}>
          <InputLabel id="project-type-filter-label" sx={{ lineHeight: 1 }}>
            Status
          </InputLabel>
          <Select
            labelId="project-type-filter-label"
            id="project-type-filter-select"
            value={status}
            label="Project risk"
            onChange={handleStatus}
            sx={{
              '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input': {
                paddingBottom: 1.1,
                paddingTop: 1.1
              }
            }}>
            <MenuItem value="">All</MenuItem>
            <MenuItem value="risk">Risk</MenuItem>
            <MenuItem value="milestone">Milestone</MenuItem>
            <MenuItem value="standUpMeetings">StandUp / Meetings</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          sx={{
            minWidth: 150,
            height: 40,
            paddingLeft: 1,
            fontSize: 26,
            cursor: 'pointer',
            justifyContent: 'center'
          }}>
          <Button
            onClick={clearFilters}
            variant="contained"
            startIcon={<ClearIcon />}
            color="primary">
            Clear Filters
          </Button>
        </FormControl>
      </Box>
      <TableContainer component={Paper} elevation={4}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => {
                if (!isEnabled && headCell.id === 'action') {
                  return;
                }
                return (
                  <TableCell
                    key={headCell.id}
                    align="left"
                    sortDirection={orderBy === headCell.id ? order : false}>
                    {headCell.filterable ? (
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}>
                        {headCell.label}
                      </TableSortLabel>
                    ) : (
                      headCell.label
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 ? (
              rows.map((row, index) => (
                <Row
                  key={index}
                  isEnabled={isEnabled}
                  row={row}
                  handleDelete={handleDelete}
                  handleStatusChange={handleStatusChange}
                />
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={7}>
                  No record found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 75, 100]}
        component="div"
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </>
  );
}
