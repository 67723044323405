import { Box, Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useRequestToServer from 'services';
import useNotification from 'hooks/useNotification';
import { useNavigate, useParams } from 'react-router-dom';

export default function CreateDocument(props) {
  const { row, isEdit, shouldClose } = props;
  const params = useParams();
  const navigate = useNavigate();
  const { successMessage, errorMessage } = useNotification();

  const { id } = params;
  const { requestToServer } = useRequestToServer();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Document name is required'),
    link: Yup.string()
      .required('Link is required')
      .matches(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i, 'Invalid URL format')
  });

  const formik = useFormik({
    initialValues: {
      name: row?.name || '',
      link: row?.link || ''
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const url = isEdit ? `project/documents/${row.id}` : `project/documents`;
      const method = isEdit ? 'put' : 'post';
      const statusCode = isEdit ? 200 : 201;
      const message = isEdit ? 'Document updated successfully.' : 'Document created successfully.';

      values['projectId'] = +id;
      let response = await requestToServer(url, values, method, true);
      if (response && response.status === statusCode) {
        successMessage(message);
        shouldClose();
        navigate(`/projects/detail/${id}`, { state: {} });
      } else {
        errorMessage(response.data.message);
      }
    }
  });

  return (
    <Box
      sx={{
        padding: 3,
        borderRadius: 2
      }}>
      <Box>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: 400 }
          }}
          onSubmit={formik.handleSubmit}
          autoComplete="off">
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              label="Name"
              variant="filled"
              margin="normal"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              inputProps={{ maxLength: 50 }}
            />
            <TextField
              label="Link"
              variant="filled"
              margin="normal"
              name="link"
              value={formik.values.link}
              onChange={formik.handleChange}
              error={formik.touched.link && Boolean(formik.errors.link)}
              helperText={formik.touched.link && formik.errors.link}
            />
          </Box>
          <Box sx={{ pr: '20px', pt: '20px', textAlign: 'right' }}>
            <Button type="submit" variant="outlined">
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
