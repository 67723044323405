import { Box, Avatar, FormLabel, Typography, Chip, Modal, Divider } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import { useState } from 'react';
import EditProject from 'components/Form/ProjectExperiment/EditExperimentalProject';
import useCommonFunction from 'hooks/useCommonFunction';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import moment from 'moment';

export default function Summary(props) {
  const { data } = props;
  const { getNameInitials, checkUpcomingDeadLine, getBackGroundColor } = useCommonFunction();
  const { getIsFeatureEnabled, getProjectRole } = useFeatureFlags();
  const role = getProjectRole(data);
  const isEnabled = getIsFeatureEnabled('PROJECT', role);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box sx={{ margin: 3 }}>
      <Box sx={{ position: 'relative' }}>
        <FormLabel>Summary</FormLabel>
        {isEnabled && (
          <EditIcon
            sx={{ position: 'absolute', right: 0, cursor: 'pointer' }}
            onClick={handleOpen}
          />
        )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 3 }}>
        <Typography sx={{ width: '20%' }}>Project name: </Typography>
        <Typography> {data.name}</Typography>
      </Box>
      <Box sx={{ margin: 3 }}>
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ width: '20%' }}>Project lead: </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '80%' }}>
            {data.projectLead.map((item, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar
                  src={item?.image}
                  alt="name"
                  sx={{
                    width: 20,
                    height: 20,
                    fontSize: '0.625rem',
                    background: getBackGroundColor(item?.name)
                  }}>
                  {getNameInitials(item?.name)}
                </Avatar>
                <Typography ml={1}>
                  {item?.name}
                  {index === data.projectLead.length - 1 ? '' : ','} &nbsp;
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box sx={{ margin: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ width: '20%' }}>Developers: </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '80%' }}>
            {data.developers.map((item, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar
                  src={item?.image}
                  alt="name"
                  sx={{
                    width: 20,
                    height: 20,
                    fontSize: '0.625rem',
                    background: getBackGroundColor(item?.name)
                  }}>
                  {getNameInitials(item?.name)}
                </Avatar>
                <Typography ml={1}>
                  {item?.name}
                  {index === data.developers.length - 1 ? '' : ','} &nbsp;
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box sx={{ margin: 3 }}>
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ width: '20%' }}>Deadline: </Typography>
          {data?.upcomingDeadline ? (
            <Chip
              label={moment.utc(data?.upcomingDeadline).format('DD-MM-YYYY')}
              color={checkUpcomingDeadLine(data?.upcomingDeadline)}
            />
          ) : (
            '-'
          )}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 3 }}>
        <Typography sx={{ width: '20%' }}>Type:</Typography>
        <Typography>{data.type}</Typography>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            borderRadius: 3,
            outline: 'none'
          }}>
          <Box sx={{ m: 3, textAlign: 'center', position: 'relative' }}>
            <Typography>Edit Summary</Typography>
            <Typography
              sx={{ position: 'absolute', right: 0, top: 0, fontWeight: 500, cursor: 'pointer' }}
              onClick={handleClose}>
              X
            </Typography>
          </Box>
          <Divider />
          <Box
            sx={{
              maxHeight: 550,
              overflowY: 'scroll',
              outline: 'none',
              '&::-webkit-scrollbar': {
                display: 'none'
              }
            }}>
            <EditProject data={data} handleClose={handleClose} />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
