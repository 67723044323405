import { Box, Popover, Card, CardContent, CardHeader, Avatar, Typography } from '@mui/material';

import { useState } from 'react';
import useCommonFunction from 'hooks/useCommonFunction';

const ProjectUsersTableCell = (props) => {
  const { members } = props;
  const { getNameInitials, getBackGroundColor } = useCommonFunction();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorPlusEl, setAnchorPlusEl] = useState(null);
  const [hoveredUser, setHoveredUser] = useState(null);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
      {members.slice(0, 4).map((member, index) => (
        <div key={index}>
          <Avatar
            onMouseEnter={(event) => {
              setAnchorEl(event.currentTarget);
              setHoveredUser(member);
            }}
            onMouseLeave={() => setAnchorEl(null)}
            src={member.avatar}
            alt="name"
            size="xs"
            sx={{
              border: '2px solid #FFF',
              cursor: 'pointer',
              position: 'relative',
              ml: -1.25,
              background: getBackGroundColor(member?.name),

              '&:not(:first-of-type)': {
                ml: -1.25
              },

              '&:hover, &:focus': {
                zIndex: '10'
              }
            }}>
            {getNameInitials(member?.name)}
          </Avatar>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none'
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}>
            <Card sx={{ minWidth: 275 }}>
              <CardHeader
                sx={{
                  padding: '8px 16px'
                }}
                avatar={
                  <Avatar
                    sx={{
                      background: getBackGroundColor(hoveredUser?.name)
                    }}
                    src={hoveredUser?.avatar}
                    alt="name"
                    size="xs">
                    {getNameInitials(hoveredUser?.name)}
                  </Avatar>
                }
                title={hoveredUser?.name}
              />
              {hoveredUser?.hours && (
                <CardContent>
                  <Typography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
                    {`${hoveredUser?.hours} hrs/week`}
                  </Typography>
                </CardContent>
              )}
            </Card>
          </Popover>
        </div>
      ))}
      {members.length > 4 && (
        <>
          <Avatar
            onMouseEnter={(event) => {
              setAnchorPlusEl(event.currentTarget);
            }}
            onMouseLeave={() => setAnchorPlusEl(null)}
            alt="name"
            size="xs"
            sx={{
              border: '2px solid #FFF',
              cursor: 'pointer',
              position: 'relative',
              ml: -1.25,

              '&:not(:first-of-type)': {
                ml: -1.25
              },

              '&:hover, &:focus': {
                zIndex: '10'
              }
            }}>
            +{members.length - 4}
          </Avatar>
          <Popover
            open={Boolean(anchorPlusEl)}
            anchorEl={anchorPlusEl}
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none'
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}>
            <Card sx={{ minWidth: 275 }}>
              {members.slice(4, members.length).map((member, index) => (
                <Box key={index}>
                  <CardHeader
                    sx={{
                      padding: '8px 16px'
                    }}
                    avatar={
                      <Avatar
                        sx={{
                          background: getBackGroundColor(member?.name)
                        }}
                        src={member?.image}
                        alt="name"
                        size="xs">
                        {getNameInitials(member?.name)}
                      </Avatar>
                    }
                    title={member?.name}
                  />
                  {member?.hours && (
                    <CardContent>
                      <Typography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
                        {`${member?.hours} hrs/week`}
                      </Typography>
                    </CardContent>
                  )}
                </Box>
              ))}
            </Card>
          </Popover>
        </>
      )}
    </Box>
  );
};

export default ProjectUsersTableCell;
