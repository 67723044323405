import React, { useReducer, useContext } from 'react';

import { Type, Variant, initialState, reducer } from './state';

const StateContext = React.createContext(initialState);
const DispatchContext = React.createContext({});

const NotificationProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
};

const useNotificationsState = () => {
  const state = useContext(StateContext);
  return state;
};

const useNotificationDispatch = () => {
  const dispatch = useContext(DispatchContext);
  return dispatch;
};

export { NotificationProvider, useNotificationsState, useNotificationDispatch, Variant, Type };
