import { useState, useEffect } from 'react';
import useRequestToServer from 'services';

function useSkills() {
  const { requestToServer } = useRequestToServer();
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await requestToServer('skill/all', '', 'get', true);
        if (response && response.status === 200) {
          let { data } = response.data;
          setSkills(data?.result);
        } else {
          setSkills([]);
        }
      } catch (error) {
        setSkills([]);
      }
    };

    fetchData();
  }, []);

  return { skills };
}

export default useSkills;
