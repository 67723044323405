import { Box, Button, TextField, MenuItem, Typography, FormHelperText } from '@mui/material';
import riskStatuses from 'StaticData/riskStatuses.json';
import useRequestToServer from 'services';
import useNotification from 'hooks/useNotification';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import TextEditor from 'components/TextEditor';

export default function CreateRisk(props) {
  const { row, isEdit, shouldClose } = props;
  const params = useParams();
  const navigate = useNavigate();
  const { successMessage, errorMessage } = useNotification();
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState('Pending');
  const [error, setError] = useState('');

  const { id } = params;
  const { requestToServer } = useRequestToServer();

  useEffect(() => {
    if (row) {
      setDescription(row.description);
      setStatus(row.status);
    }
  }, [row]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!description) {
      setError('Risk description is required');
      return;
    }
    const url = isEdit ? `project/risk/${row.id}` : `project/risk`;
    const method = isEdit ? 'put' : 'post';
    const statusCode = isEdit ? 200 : 201;
    const message = isEdit ? 'Risk updated successfully.' : 'Risk created successfully.';

    let trimDescription = description.replace(/<p>\s*&nbsp;\s*<\/p>/gi, '');
    const values = {
      projectId: +id,
      description: trimDescription,
      status
    };
    let response = await requestToServer(url, values, method, true);
    if (response && response.status === statusCode) {
      successMessage(message);
      shouldClose();
      navigate(`/projects/detail/${id}`, { state: {} });
    } else {
      errorMessage(response.data.message);
    }
  };

  return (
    <Box
      sx={{
        padding: 3,
        borderRadius: 2
      }}>
      <Box>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: 500 }
          }}
          onSubmit={handleSubmit}
          autoComplete="off">
          <Box sx={{ display: 'flex', flexDirection: 'column', width: 500 }}>
            <TextEditor
              placeholder="Add risk description"
              onUpdate={(value) => {
                setDescription(value);
              }}
              value={description}
            />
            {error && <FormHelperText sx={{ color: '#d32f2f' }}>{error}</FormHelperText>}
            {isEdit && (
              <TextField
                id="filled-select-currency"
                select
                label="Status"
                variant="filled"
                fullWidth
                name="status"
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}>
                {riskStatuses.map((option) => (
                  <MenuItem key={option.name} value={option.value}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography ml={1}>{option?.name}</Typography>
                    </Box>
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Box>
          <Box sx={{ pr: '20px', pt: '20px', textAlign: 'right' }}>
            <Button type="submit" variant="outlined">
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
