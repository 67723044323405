import { useState, useEffect } from 'react';
import useRequestToServer from 'services';

function useEmployees() {
  const { requestToServer } = useRequestToServer();
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await requestToServer('user/all', '', 'get', true);
        if (response && response.status === 200) {
          let { data } = response.data;
          setEmployees(data?.result);
        } else {
          setEmployees([]);
        }
      } catch (error) {
        setEmployees([]);
      }
    };

    fetchData();
  }, []);

  return { employees };
}

export default useEmployees;
