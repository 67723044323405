import { Box, Typography, FormLabel, Chip, Modal, Divider } from '@mui/material';

import EmployeeNameCell from 'components/Table/Employee/List/EmployeeNameCell';
import ProjectListCell from 'components/Table/Employee/List/ProjectListCell';
import TechSkillsListCell from 'components/Table/Employee/List/TechSkillsListCell';
import EditIcon from '@mui/icons-material/Edit';
import { useState } from 'react';
import EditEmployee from 'components/Form/Employee/EditEmployee';

export default function Summary(props) {
  const { data } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box sx={{ padding: 3 }}>
      <Box sx={{ position: 'relative' }}>
        <FormLabel>Summary</FormLabel>
        <EditIcon sx={{ position: 'absolute', right: 0, cursor: 'pointer' }} onClick={handleOpen} />
      </Box>
      <Box sx={{ margin: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ width: '20%' }}>Employee name: </Typography>
          <EmployeeNameCell name={data?.name} image={data?.image} />
        </Box>
      </Box>
      <Box sx={{ margin: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ width: '20%' }}>Email: </Typography>
          <Typography>{data?.email || '-'}</Typography>
        </Box>
      </Box>
      <Box sx={{ margin: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ width: '20%' }}>Allocation: </Typography>
          <ProjectListCell projects={data?.projects} totalHours={data?.totalAllocatedHours} />
        </Box>
      </Box>
      <Box sx={{ margin: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ width: '20%' }}>Availability: </Typography>
          <Chip
            label={`${data?.availability} hrs/week`}
            color={data?.availability <= 10 ? 'error' : 'success'}
          />
        </Box>
      </Box>
      <Box sx={{ margin: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ width: '20%' }}>Position: </Typography>
          <Typography>{data?.position?.name || '-'}</Typography>
        </Box>
      </Box>
      <Box sx={{ margin: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ width: '20%' }}>Skills: </Typography>
          <TechSkillsListCell techSkills={data?.skills} />
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            borderRadius: 3,
            outline: 'none'
          }}>
          <Box sx={{ m: 3, textAlign: 'center', position: 'relative' }}>
            <Typography>Edit Summary</Typography>
            <Typography
              sx={{ position: 'absolute', right: 0, top: 0, fontWeight: 500, cursor: 'pointer' }}
              onClick={handleClose}>
              X
            </Typography>
          </Box>
          <Divider />
          <Box
            sx={{
              maxHeight: 550,
              overflowY: 'scroll',
              outline: 'none',
              '&::-webkit-scrollbar': {
                display: 'none'
              }
            }}>
            <EditEmployee data={data} handleClose={handleClose} />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
