import React from 'react';
import Chip from '@mui/material/Chip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ActionTableCell from './ActionTableCell';
import CheckListItem from './CheckListItem';
import moment from 'moment';
import { Typography } from '@mui/material';

function Row(props) {
  const { row, shouldEdit, handleDelete, isEnabled } = props;
  return (
    <React.Fragment>
      <TableRow>
        <TableCell width={300} align="left" component="th" scope="row">
          <Typography
            sx={{
              width: '250px',
              display: 'block',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}>
            {row.name}
          </Typography>
        </TableCell>
        <TableCell align="left">{moment.utc(row.startDate).format('DD-MM-YYYY')}</TableCell>
        <TableCell align="left">{moment.utc(row.endDate).format('DD-MM-YYYY')}</TableCell>
        <TableCell align="left">
          <Chip
            label={row.status}
            color={
              row.status === 'Completed'
                ? 'success'
                : row.status === 'Pending'
                ? 'primary'
                : 'warning'
            }
          />
        </TableCell>
        <TableCell align="left">
          <CheckListItem data={row.checklists} />
        </TableCell>

        {isEnabled && (
          <TableCell align="left">
            <ActionTableCell
              row={row}
              handleDelete={handleDelete}
              shouldEdit={() => shouldEdit(row)}
            />
          </TableCell>
        )}
      </TableRow>
    </React.Fragment>
  );
}

export default function Index(props) {
  const { rows, shouldOpenEditForm, handleDelete, isEnabled } = props;
  return (
    <>
      <TableContainer component={Paper} elevation={4}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Start date</TableCell>
              <TableCell align="left">End date</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Checklist</TableCell>
              {isEnabled && <TableCell align="left">Action</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.length > 0 ? (
              rows?.map((row) => (
                <Row
                  isEnabled={isEnabled}
                  key={row.name}
                  row={row}
                  handleDelete={handleDelete}
                  shouldEdit={shouldOpenEditForm}
                />
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={7}>
                  No record found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
