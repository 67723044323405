import { Box, Popover, Card, Chip, ListItem, ListItemText } from '@mui/material';

import { useState } from 'react';

const ProjectListCell = (props) => {
  const { projects, totalHours } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
      <Chip
        onMouseEnter={(event) => {
          if (totalHours) {
            setAnchorEl(event.currentTarget);
          }
        }}
        onMouseLeave={() => setAnchorEl(null)}
        label={`${totalHours} hrs/week`}
        color={'success'}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <Card sx={{ minWidth: 275 }}>
          {projects.map((project, index) => (
            <div key={index}>
              <ListItem>
                <ListItemText
                  sx={{
                    width: 175,
                    display: 'block',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    marginRight: '15px'
                  }}
                  primary={project.name}
                />
                <ListItemText primary={`${project.allocatedHours} hrs/week`} />
              </ListItem>
            </div>
          ))}
        </Card>
      </Popover>
    </Box>
  );
};

export default ProjectListCell;
