import {
  Box,
  Button,
  TextField,
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
  Avatar,
  FormHelperText,
  Checkbox,
  FormControlLabel,
  Autocomplete
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import projectType from 'StaticData/projectType.json';
import useCommonFunction from 'hooks/useCommonFunction';
import useEmployees from 'hooks/useEmployees';
import useNotification from 'hooks/useNotification';
import useRequestToServer from 'services';

export default function CreateProject() {
  const navigate = useNavigate();
  const { requestToServer } = useRequestToServer();
  const { getNameInitials, getLables, getBackGroundColor } = useCommonFunction();
  const { successMessage, errorMessage } = useNotification();
  const { employees } = useEmployees();
  const [forms, setForms] = useState([{ id: '', allocatedHours: 1, error: '' }]);
  const [leadsforms, setLeadsForms] = useState([{ id: '', allocatedHours: 1, error: '' }]);
  const [projectMembers, setProjectMembers] = useState([]);

  useEffect(() => {
    setProjectMembers(employees);
  }, [employees]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Project name is required'),
    type: Yup.string(),
    emailSendStatus: Yup.boolean()
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      type: 'Fix',
      emailSendStatus: true
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let projectLeads = [...leadsforms];
      let projectDev = [...forms];
      projectLeads = projectLeads.filter((obj) => {
        delete obj.error;
        if (obj.id !== '') {
          return true;
        }
        return false;
      });

      projectDev = projectDev.filter((obj) => {
        delete obj.error;
        if (obj.id !== '') {
          return true;
        }
        return false;
      });
      values['projectLead'] = projectLeads;
      values['projectMembers'] = projectDev;

      let response = await requestToServer('project', values, 'post', true);
      if (response && response.status === 201) {
        successMessage('Project created successfully.');
        navigate('/projects');
      } else {
        errorMessage(response.data.message);
      }
    }
  });

  const handleProjectAllocationChange = (index, event) => {
    const values = [...forms];
    const all = [...forms, ...leadsforms];
    let alreadySelected = false;
    if (event.target.name === 'id') {
      alreadySelected = all.some((obj) => obj.id === event.target.value);
    }
    if (alreadySelected) {
      values[index]['error'] = 'This member already allocated. please select another member';
      setForms(values);
    } else {
      values[index]['error'] = '';
      values[index][event.target.name] = event.target.value;
      setForms(values);
    }
  };

  const handleAddForm = () => {
    const hasError = forms.some((obj) => {
      if (!obj.id || !obj.allocatedHours) {
        obj.error = 'Both field is required!';
        return true;
      }
    });
    const values = [...forms];
    if (hasError) {
      setForms(values);
      return;
    }
    values.push({ id: '', allocatedHours: 0, error: '' });
    setForms(values);
  };

  const removeProjectAllocation = (index) => {
    const values = [...forms];
    values.splice(index, 1);
    setForms(values);
  };

  const handleProjectLeadChange = (index, event) => {
    const values = [...leadsforms];
    const all = [...forms, ...leadsforms];
    let alreadySelected = false;
    if (event.target.name === 'id') {
      alreadySelected = all.some((obj) => obj.id === event.target.value);
    }
    if (alreadySelected) {
      values[index]['error'] = 'This member already allocated. please select another member';
      setLeadsForms(values);
    } else {
      values[index]['error'] = '';
      values[index][event.target.name] = event.target.value;
      setLeadsForms(values);
    }
  };

  const handleLeadAddForm = () => {
    const hasError = leadsforms.some((obj) => {
      if (!obj.id || !obj.allocatedHours) {
        obj.error = 'Both field is required!';
        return true;
      }
    });
    const values = [...leadsforms];
    if (hasError) {
      setLeadsForms(values);
      return;
    }
    values.push({ id: '', allocatedHours: 0, error: '' });
    setLeadsForms(values);
  };

  const removeProjectLead = (index) => {
    const values = [...leadsforms];
    values.splice(index, 1);
    setLeadsForms(values);
  };
  return (
    <Box
      sx={{
        padding: 3,
        border: '2px solid rgba(224, 224, 224, 1)',
        borderRadius: 2,
        width: 600
      }}>
      <Box>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: 500 }
          }}
          onSubmit={formik.handleSubmit}
          autoComplete="off">
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              label="Project name"
              name="name"
              variant="filled"
              fullWidth
              margin="normal"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              inputProps={{ maxLength: 50 }}
            />
            <FormControl sx={{ m: 1 }}>
              <InputLabel id="demo-multiple-chip-label">Project lead</InputLabel>
              <Box mt={5}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {leadsforms.map((form, index) => (
                    <Box key={index}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Autocomplete
                          options={projectMembers}
                          getOptionLabel={(option) => getLables(employees, option.id)}
                          renderOption={(props, option) => (
                            <Box component="li" {...props}>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Avatar
                                  src={option?.image}
                                  alt="name"
                                  sx={{
                                    width: 25,
                                    height: 25,
                                    fontSize: '0.625rem',
                                    background: getBackGroundColor(option?.name)
                                  }}>
                                  {getNameInitials(option.name)}
                                </Avatar>
                                <Typography ml={1}>{getLables(employees, option.id)}</Typography>
                              </Box>
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Members"
                              name="id"
                              variant="filled"
                              sx={{ width: '300px !important' }}
                              error={!!form.error && !form.id}
                              onChange={(event) => handleProjectLeadChange(index, event)}
                            />
                          )}
                          value={projectMembers.find((option) => option.id === form.id) || null}
                          onChange={(event, newValue) =>
                            handleProjectLeadChange(index, {
                              target: { name: 'id', value: newValue ? newValue.id : '' }
                            })
                          }
                        />
                        <TextField
                          label="Hours/week"
                          name="allocatedHours"
                          type="number"
                          InputProps={{ inputProps: { min: 1, max: 40 } }}
                          sx={{ width: '100px !important' }}
                          variant="filled"
                          value={form.allocatedHours}
                          error={!!form.error && !form.allocatedHours}
                          onChange={(event) => handleProjectLeadChange(index, event)}
                        />
                        {leadsforms && leadsforms.length > 0 && (
                          <CancelIcon
                            color="error"
                            sx={{ cursor: 'pointer' }}
                            onClick={() => removeProjectLead(index)}
                          />
                        )}
                      </Box>
                      <FormHelperText className="Mui-error">
                        {form.error && form.error}
                      </FormHelperText>
                    </Box>
                  ))}
                </Box>
                <Button
                  sx={{ width: 100 }}
                  onClick={handleLeadAddForm}
                  startIcon={<AddIcon />}
                  disabled={projectMembers.length === leadsforms.length}
                  color="primary">
                  add
                </Button>
              </Box>
            </FormControl>
            <TextField
              id="filled-select-currency"
              select
              label="Project Type"
              name="type"
              value={formik.values.type}
              variant="filled"
              fullWidth
              onChange={formik.handleChange}>
              {projectType.map((option) => (
                <MenuItem key={option.name} value={option.value}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography ml={1}>{option?.name}</Typography>
                  </Box>
                </MenuItem>
              ))}
            </TextField>

            <FormControl sx={{ m: 1 }}>
              <InputLabel id="demo-multiple-chip-label">Project members</InputLabel>
              <Box mt={5}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {forms.map((form, index) => (
                    <Box key={index}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Autocomplete
                          options={projectMembers}
                          getOptionLabel={(option) => getLables(employees, option.id)}
                          renderOption={(props, option) => (
                            <Box component="li" {...props}>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Avatar
                                  src={option?.image}
                                  alt="name"
                                  sx={{
                                    width: 25,
                                    height: 25,
                                    fontSize: '0.625rem',
                                    background: getBackGroundColor(option?.name)
                                  }}>
                                  {getNameInitials(option.name)}
                                </Avatar>
                                <Typography ml={1}>{getLables(employees, option.id)}</Typography>
                              </Box>
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Members"
                              name="id"
                              variant="filled"
                              sx={{ width: '300px !important' }}
                              error={!!form.error && !form.id}
                              onChange={(event) => handleProjectAllocationChange(index, event)}
                            />
                          )}
                          value={projectMembers.find((option) => option.id === form.id) || null}
                          onChange={(event, newValue) =>
                            handleProjectAllocationChange(index, {
                              target: { name: 'id', value: newValue ? newValue.id : '' }
                            })
                          }
                        />
                        <TextField
                          label="Hours/week"
                          name="allocatedHours"
                          type="number"
                          InputProps={{ inputProps: { min: 1, max: 40 } }}
                          sx={{ width: '100px !important' }}
                          variant="filled"
                          value={form.allocatedHours}
                          error={!!form.error && !form.allocatedHours}
                          onChange={(event) => handleProjectAllocationChange(index, event)}
                        />

                        {forms && forms.length > 0 && (
                          <CancelIcon
                            color="error"
                            sx={{ cursor: 'pointer' }}
                            onClick={() => removeProjectAllocation(index)}
                          />
                        )}
                      </Box>
                      <FormHelperText className="Mui-error">
                        {form.error && form.error}
                      </FormHelperText>
                    </Box>
                  ))}
                </Box>
                <Button
                  sx={{ width: 100 }}
                  onClick={handleAddForm}
                  startIcon={<AddIcon />}
                  disabled={projectMembers.length === forms.length}
                  color="primary">
                  add
                </Button>
              </Box>
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  name="emailSendStatus"
                  checked={formik.values.emailSendStatus}
                  value={formik.values.emailSendStatus}
                  onChange={formik.handleChange}
                  color="primary"
                />
              }
              label="Send Project Email"
            />
          </Box>
          <Box sx={{ pr: '30px', textAlign: 'right' }}>
            <Button type="submit" variant="outlined">
              Save
            </Button>
            &nbsp;
            <Button variant="outlined" onClick={() => navigate(-1)}>
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
