import React, { useEffect, useRef, useState } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { Button, ButtonGroup, Divider, Menu, Paper, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import useRequestToServer from 'services';
import useNotification from 'hooks/useNotification';
import moment from 'moment';
import CustomDateRange from 'components/Form/ProjectExperiment/CustomDateRange';
import { useAuth } from 'providers/AuthProvider';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import EditIcon from '@mui/icons-material/Edit';
import TextEditor from 'components/TextEditor';

export default function DailyStanup(props) {
  const { data } = props;
  const { requestToServer } = useRequestToServer();
  const { successMessage, errorMessage } = useNotification();
  const { user } = useAuth();
  const { getIsFeatureEnabled, getProjectRole } = useFeatureFlags();
  const role = getProjectRole(data);
  const isEnabled = getIsFeatureEnabled('DAILY_STANDUP', role);
  // Date format
  const currentDate = moment.utc();
  const lastMonthDate = currentDate.clone().subtract(1, 'months');
  const formattedLastMonthDate = lastMonthDate.format('YYYY-MM-DD');
  const formattedCurrentDate = currentDate.format('YYYY-MM-DD');

  const [startDate, setStartDate] = useState(currentDate.startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(currentDate.endOf('month').format('YYYY-MM-DD'));
  const [message, setMessage] = useState('');
  const [dailyStanups, setDailyStandups] = useState([]);
  const [isInputEnabled, setIsInputEnabled] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isAlreadyCreated, setIsAlreadyCreated] = useState(false);
  const [selectedStandup, setSelectedStandup] = useState('');
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [isTruncated, setIsTruncated] = useState();
  const [date, setDate] = useState(formattedCurrentDate);
  const [selectedStandupDate, setSelectedStandupDate] = useState('');

  const bottomElement = useRef(null);

  const getDailyStandupMeetingList = async () => {
    let response = await requestToServer(
      `experiment/standup-meetings/${data.id}?startDate=${startDate}&endDate=${endDate}`,
      '',
      'get',
      true
    );
    if (response && response.status === 200) {
      let { data } = response.data;
      data.result.sort((a, b) => new Date(a.date) - new Date(b.date));
      setDailyStandups(data.result);
      setIsTruncated(Array(data?.result?.length).fill(true));
    } else {
      errorMessage(response.data.message);
    }
  };

  const getDailyStandupMeetingStatus = async () => {
    let response = await requestToServer(
      `experiment/standup-meetings/status/${data.id}`,
      '',
      'get',
      true
    );
    if (response && response.status === 200) {
      let { data } = response.data;
      setIsAlreadyCreated(!data.result);
    } else {
      errorMessage(response.data.message);
    }
  };

  useEffect(() => {
    getDailyStandupMeetingList();
  }, [startDate, endDate]);

  useEffect(() => {
    getDailyStandupMeetingStatus();
  }, []);

  const handleSubmit = async () => {
    message.replace(/(?:\r\n|\r|\n)/g, '<br>');
    let trimDescription = message.replace(/<p>\s*&nbsp;\s*<\/p>/gi, '');
    const obj = {
      expProjectId: Number(data.id),
      description: trimDescription,
      createdBy: user.loginDetails.id
    };
    const addobj = {
      expProjectId: Number(data.id),
      description: trimDescription,
      date: date,
      createdBy: user.loginDetails.id
    };
    if (isEdit) {
      let response = await requestToServer(
        `experiment/standup-meetings/${selectedStandup}`,
        obj,
        'put',
        true
      );
      if (response && response.status === 200) {
        successMessage('Daily standup updated successfully.');
        getDailyStandupMeetingList();
        setMessage('');
        setIsEdit(false);
        setSelectedStandup('');
      } else {
        errorMessage(response.data.message);
      }
    } else {
      let response = await requestToServer(`experiment/standup-meetings`, addobj, 'post', true);
      if (response && response.status === 201) {
        successMessage('Daily standup created successfully.');
        getDailyStandupMeetingList();
        setMessage('');
        setIsAlreadyCreated(true);
      } else {
        errorMessage(response.data.message);
      }
    }
  };

  const handleDateChange = async (e) => {
    const selectedDate = e.target.value;
    setDate(selectedDate);
    const existingStandup = dailyStanups.find((item) =>
      moment.utc(item.date).isSame(moment.utc(selectedDate), 'day')
    );
    if (existingStandup) {
      setIsEdit(true);
      setSelectedStandup(existingStandup.id);
      setMessage(existingStandup.description.replace(/<br\s*\/?>/gm, '\n'));
      setIsAlreadyCreated(true);
    } else {
      setIsEdit(false);
      setMessage('');
      setIsAlreadyCreated(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      bottomElement.current.scrollIntoView({
        behavior: 'smooth'
      });
    }, 10);
  }, [dailyStanups]);

  const handleFilter = (filter) => {
    const startOfMonth = moment.utc().startOf('month');
    const endOfMonth = moment.utc().endOf('month');
    let start, end;

    switch (filter) {
      case 'LAST_MONTH': {
        start = startOfMonth.subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
        end = endOfMonth.subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
        setStartDate(start);
        setEndDate(end);
        break;
      }
      case 'LAST_TWO_MONTH': {
        start = startOfMonth.subtract(2, 'month').startOf('month').format('YYYY-MM-DD');
        end = endOfMonth.subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
        setStartDate(start);
        setEndDate(end);
        break;
      }

      default: {
        start = startOfMonth;
        end = endOfMonth;
        setStartDate(start);
        setEndDate(end);
      }
    }
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCustomDateSubmit = (values) => {
    handleCloseUserMenu();
    setStartDate(moment.utc(values.start).format('YYYY-MM-DD'));
    setEndDate(moment.utc(values.end).format('YYYY-MM-DD'));
  };

  useEffect(() => {
    if (isEnabled) {
      setIsInputEnabled(!isAlreadyCreated);
    } else {
      setIsInputEnabled(false);
    }
  }, [isEnabled, isAlreadyCreated]);

  useEffect(() => {
    if (isEnabled) {
      setIsInputEnabled(true);
    } else {
      setIsInputEnabled(false);
    }
  }, [isEnabled, isAlreadyCreated]);

  const toggleTruncate = (index) => {
    const newTruncationState = [...isTruncated];
    newTruncationState[index] = !newTruncationState[index];
    setIsTruncated(newTruncationState);
  };

  return (
    <Box>
      <Paper elevation={4}>
        <Box>
          <ButtonGroup variant="text" color="primary" aria-label="medium secondary button group">
            <Button key="one" onClick={() => handleFilter('LAST_TWO_MONTH')}>
              Last 2 months
            </Button>
            <Button key="two" onClick={() => handleFilter('LAST_MONTH')}>
              Last month
            </Button>
            <Button key="three" onClick={() => handleFilter('CURRENT_MONTH')}>
              Current month
            </Button>
            <Button key="four" onClick={handleOpenUserMenu}>
              Date range
            </Button>
          </ButtonGroup>
          <Divider />
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}>
            <CustomDateRange handleCustomDateSubmit={handleCustomDateSubmit} />
          </Menu>
          <Timeline
            position="alternate"
            sx={{
              maxHeight: 410,
              overflowY: 'scroll'
            }}>
            {dailyStanups.length > 0 ? (
              dailyStanups.map((item, index) => (
                <TimelineItem key={index}>
                  <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    align={index % 2 === 0 ? 'right' : 'left'}
                    variant="body2"
                    color="text.secondary">
                    {moment.utc(item.date).format('YYYY-MM-DD')}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot>
                      <LaptopMacIcon />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Paper elevation={3} sx={{ padding: '6px 16px' }}>
                      <Box
                        sx={{
                          position: 'relative',
                          width: '100%'
                        }}>
                        {(isEnabled || item.createdByUser.id === user.loginDetails.id) && (
                          <EditIcon
                            sx={{
                              height: 20,
                              width: 20,
                              position: 'absolute',
                              right: index % 2 === 0 ? 0 : 'unset',
                              left: index % 2 === 0 ? 'unset' : 0,
                              cursor: 'pointer'
                            }}
                            onClick={() => {
                              setIsEdit(true);
                              setSelectedStandup(item.id);
                              setMessage(item.description.replace(/<br\s*\/?>/gm, '\n'));
                              setSelectedStandupDate(moment.utc(item.date).format('YYYY-MM-DD'));
                            }}
                          />
                        )}
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            flexDirection: index % 2 === 0 ? 'row' : 'row-reverse'
                          }}>
                          <Typography variant="caption" component="span">
                            {item.createdByUser.name}
                          </Typography>
                          <Typography variant="caption" component="span">
                            &nbsp;{item.isEdited ? '(Edited)' : ''}&nbsp;
                          </Typography>
                        </Box>
                      </Box>
                      <Typography
                        sx={{
                          textAlign: 'left',
                          maxWidth: 500,
                          wordBreak: 'break-word',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          '-webkit-line-clamp': isTruncated[index] ? '4' : 'unset',
                          '-webkit-box-orient': 'vertical'
                        }}
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      />
                      {isTruncated[index] ? (
                        <Button
                          sx={{ minWidth: 30, padding: 0 }}
                          onClick={() => toggleTruncate(index)}>
                          More
                        </Button>
                      ) : (
                        <Button
                          sx={{ minWidth: 30, padding: 0 }}
                          onClick={() => toggleTruncate(index)}>
                          Less
                        </Button>
                      )}
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              ))
            ) : (
              <Typography align="center">No record found</Typography>
            )}
            <div className="bottom" ref={bottomElement} />
          </Timeline>
        </Box>
        {isInputEnabled && (
          <Paper
            elevation={4}
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              padding: '8px 16px 8px 16px'
            }}>
            <TextEditor
              placeholder="Add MOM of meetings"
              onUpdate={(value) => {
                setMessage(value);
              }}
              value={message}
            />
            <TextField
              sx={{ paddingLeft: 2, justifyContent: 'center', width: '23%' }}
              id="date"
              type="date"
              variant="filled"
              name="Date"
              onChange={handleDateChange}
              value={selectedStandupDate || formattedCurrentDate}
              inputProps={{ min: formattedLastMonthDate, max: formattedCurrentDate }}
              onKeyDown={(event) => {
                event.preventDefault();
              }}
            />
            <Button
              sx={{
                marginLeft: '20px'
              }}
              variant="contained"
              endIcon={<SendIcon />}
              onClick={handleSubmit}>
              Send
            </Button>
          </Paper>
        )}
      </Paper>
    </Box>
  );
}
