import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'providers/AuthProvider';
import { useLoader } from 'providers/LoaderProvider';
const statusCode = [401, 403, 422, 400, 500, 404];

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error && error.response && statusCode.includes(error?.response?.status)) {
      return Promise.resolve(error.response);
    } else {
      return Promise.reject(error);
    }
  }
);

const getHeaders = (isAuthorized, authToken, isFormWithImg) => {
  let config = {
    headers: {
      Accept: 'application/json'
    }
  };
  if (isAuthorized) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }
  if (isFormWithImg) {
    config.headers['Content-Type'] = 'multipart/form-data';
  }
  return config.headers;
};

const useRequestToServer = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const authToken = user?.token;
  const { setIsLoading } = useLoader();

  const requestToServer = (url, data, method, isAuthorized = false, isFormWithImg = false) => {
    const API_URL = process.env.REACT_APP_API_URL;
    let requestUrl = `${API_URL}${url}`;
    let headers = getHeaders(isAuthorized, authToken, isFormWithImg);
    setIsLoading(true);
    //below override bcz it's static for now
    //requestUrl = 'https://jsonplaceholder.typicode.com/posts';
    //method = 'get';
    return new Promise((resolve, reject) => {
      axios({ method, url: requestUrl, headers, data })
        .then((response) => {
          setIsLoading(false);
          if (response && response.status === 401) {
            navigate('/', { replace: true });
          }
          resolve(response);
        })
        .catch((error) => {
          setIsLoading(false);
          reject(error);
        });
    });
  };

  return { requestToServer };
};

export default useRequestToServer;
