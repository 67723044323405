import { Box, Avatar, Typography } from '@mui/material';
import useCommonFunction from 'hooks/useCommonFunction';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

const EmployeeNameCell = (props) => {
  const { name, image } = props;
  const { getNameInitials, getBackGroundColor } = useCommonFunction();
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          src={image}
          alt={name}
          size="xs"
          sx={{
            border: '2px solid #FFF',
            cursor: 'pointer',
            position: 'relative',
            mr: 1,
            background: getBackGroundColor(name)
          }}>
          {getNameInitials(name)}
        </Avatar>
        <Typography> {capitalizeFirstLetter(name)}</Typography>
      </Box>
    </Box>
  );
};

export default EmployeeNameCell;
