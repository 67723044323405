import {
  Box,
  Button,
  TextField,
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
  Select,
  FilledInput,
  Chip,
  Avatar,
  FormHelperText
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import usePositions from 'hooks/usePositions';
import useCommonFunction from 'hooks/useCommonFunction';

export default function EditProfile(props) {
  const { data, handleSubmit, skillList } = props;
  const { getLables, getNameInitials } = useCommonFunction();
  const { positions } = usePositions();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Employee name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    positionId: Yup.string().required('Position is required'),
    skills: Yup.array()
  });

  const formik = useFormik({
    initialValues: {
      name: data?.name || '',
      email: data?.email || '',
      positionId: data?.positionId || '',
      skills: data?.skills?.map(({ id }) => id) || []
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
      values['positionId'] = values.positionId.toString();
    }
  });

  return (
    <Box
      sx={{
        padding: 3,
        borderRadius: 2
      }}>
      <Box>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: 400 }
          }}
          onSubmit={formik.handleSubmit}
          autoComplete="off">
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              label="Employee name"
              name="name"
              variant="filled"
              fullWidth
              margin="normal"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              inputProps={{ maxLength: 50 }}
            />
            <TextField
              label="Email"
              name="email"
              variant="filled"
              fullWidth
              margin="normal"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              inputProps={{ maxLength: 50, readOnly: true }}
            />
            <TextField
              id="filled-select-currency"
              select
              label="Position"
              name="positionId"
              variant="filled"
              fullWidth
              value={formik.values.positionId}
              onChange={formik.handleChange}
              error={formik.touched.positionId && Boolean(formik.errors.positionId)}
              helperText={formik.touched.positionId && formik.errors.positionId}>
              {positions.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography ml={1}>{option?.name}</Typography>
                  </Box>
                </MenuItem>
              ))}
            </TextField>

            <FormControl sx={{ m: 1 }}>
              <InputLabel
                className={formik.touched.skills && formik.errors.skills ? 'Mui-error' : ''}
                id="demo-multiple-chip-label">
                Skills
              </InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                fullWidth
                name="skills"
                input={<FilledInput />}
                value={formik.values.skills}
                onChange={formik.handleChange}
                MenuProps={{
                  sx: {
                    '& ul': {
                      height: '250px'
                    }
                  }
                }}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={getLables(skillList, value)} />
                    ))}
                  </Box>
                )}>
                {skillList.map((option) => (
                  <MenuItem key={option.name} value={option.id}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar
                        sx={{
                          background: 'transparent'
                        }}
                        src={option?.image}
                        alt={option?.name}>
                        {getNameInitials(option?.name)}
                      </Avatar>
                      <Typography ml={1}>{option?.name}</Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText className="Mui-error">
                {formik.touched.skills && formik.errors.skills}
              </FormHelperText>
            </FormControl>
          </Box>
          <Box sx={{ pr: '20px', textAlign: 'right' }}>
            <Button type="submit" variant="outlined">
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
