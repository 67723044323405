import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';

import ContentPlanTableList from 'components/Table/ContentPlan';
import { useNavigate } from 'react-router-dom';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { useAuth } from 'providers/AuthProvider';

const ContentPlanList = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const { getIsFeatureEnabled } = useFeatureFlags();
  //Below code added just for reference, it'll be always true for now
  const isEnabled = getIsFeatureEnabled('CONTENT_PLAN', user?.loginDetails?.role);
  return (
    <>
      {isEnabled && (
        <Box sx={{ float: 'right', paddingBottom: 2 }} color="primary" px={2}>
          <Button
            onClick={() => navigate('create')}
            variant="contained"
            startIcon={<AddIcon />}
            color="primary">
            add new content
          </Button>
        </Box>
      )}
      <ContentPlanTableList />
    </>
  );
};

export default ContentPlanList;
