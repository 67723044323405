import React, { useState } from 'react';
import { Box, Popover, Chip, Card, ListItem, ListItemText } from '@mui/material';

const CheckListItem = ({ data }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
      <Chip
        onMouseEnter={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        onMouseLeave={() => setAnchorEl(null)}
        label={`View`}
        color={'success'}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        PaperProps={{
          sx: {
            top: '50% !important',
            left: '50% !important',
            transform: 'translate(-50%, -50%) !important'
          }
        }}>
        <Card sx={{ minWidth: 275 }}>
          {data.map((item, index) => (
            <div key={index}>
              <ListItem>
                <ListItemText sx={{ width: '85%' }} primary={item.name} />
                <ListItemText
                  primary={`${item.status}`}
                  sx={{ width: '15%', color: `${item.status == 'Completed' ? 'green' : 'red'}` }}
                />
              </ListItem>
            </div>
          ))}
        </Card>
      </Popover>
    </Box>
  );
};

export default CheckListItem;
