import { Box, Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

export default function CustomDateRange(props) {
  const { handleCustomDateSubmit } = props;

  const validationSchema = Yup.object().shape({
    start: Yup.string().required('Start date is required'),
    end: Yup.string().required('End date is required')
  });

  const formik = useFormik({
    initialValues: {
      start: '',
      end: ''
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleCustomDateSubmit(values);
    }
  });

  return (
    <Box
      sx={{
        padding: 3,
        borderRadius: 2
      }}>
      <Box>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: 300 }
          }}
          onSubmit={formik.handleSubmit}
          autoComplete="off">
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              id="startdate"
              label="Start date"
              name="start"
              type="date"
              variant="filled"
              value={formik.values.start}
              onKeyDown={(event) => {
                event.preventDefault();
              }}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                min: `${moment
                  .utc()
                  .startOf('month')
                  .subtract(2, 'month')
                  .startOf('month')
                  .format('YYYY-MM-DD')}`,
                max: `${moment.utc().format('YYYY-MM-DD')}`
              }}
              error={formik.touched.start && Boolean(formik.errors.start)}
              helperText={formik.touched.start && formik.errors.start}
            />
            <TextField
              id="enddate"
              label="End date"
              name="end"
              type="date"
              variant="filled"
              value={formik.values.end}
              onKeyDown={(event) => {
                event.preventDefault();
              }}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                min: `${moment
                  .utc()
                  .startOf('month')
                  .subtract(2, 'month')
                  .startOf('month')
                  .format('YYYY-MM-DD')}`,
                max: `${moment.utc().format('YYYY-MM-DD')}`
              }}
              error={formik.touched.end && Boolean(formik.errors.end)}
              helperText={formik.touched.end && formik.errors.end}
            />
          </Box>
          <Box sx={{ pr: '20px', pt: '20px', textAlign: 'right' }}>
            <Button type="submit" variant="outlined">
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
