import {
  Box,
  Button,
  TextField,
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
  Avatar,
  FormHelperText,
  Select,
  FilledInput,
  Chip
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import statuses from 'StaticData/status.json';
import categories from 'StaticData/category.json';

import useRequestToServer from 'services';
import useCommonFunction from 'hooks/useCommonFunction';
import useEmployees from 'hooks/useEmployees';
import useNotification from 'hooks/useNotification';
// import moment from 'moment';

export default function CreateContent() {
  const navigate = useNavigate();
  const { requestToServer } = useRequestToServer();
  const { getLables, getNameInitials, getBackGroundColor } = useCommonFunction();
  const { employees } = useEmployees();
  const { successMessage, errorMessage } = useNotification();
  const [projectMembers, setProjectMembers] = useState([]);

  useEffect(() => {
    setProjectMembers(employees);
  }, [employees]);

  const validationSchema = Yup.object().shape({
    documentLink: Yup.string()
      .required('Document link is required')
      .matches(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i, 'Invalid URL format'),
    topic: Yup.string().required('Topic name is required'),
    category: Yup.string().required('Category is required'),
    userIds: Yup.array(),
    status: Yup.string(),
    deadline: Yup.string().required('Deadline is required'),
    allocatedBy: Yup.number().required('Allocated by is required')
  });

  const formik = useFormik({
    initialValues: {
      documentLink: '',
      topic: '',
      category: '',
      userIds: [],
      status: 'Pending',
      deadline: '',
      allocatedBy: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let response = await requestToServer('content-plan', values, 'post', true);
      if (response && response.status === 200) {
        successMessage('Content plan created successfully');
        navigate('/content-plan');
      } else {
        errorMessage(response.data.message);
      }
    }
  });

  return (
    <Box
      sx={{
        padding: 3,
        border: '2px solid rgba(224, 224, 224, 1)',
        borderRadius: 2,
        width: 600
      }}>
      <Box>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: 500 }
          }}
          onSubmit={formik.handleSubmit}
          autoComplete="off">
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              label="Document link"
              name="documentLink"
              variant="filled"
              fullWidth
              margin="normal"
              value={formik.values.documentLink}
              onChange={formik.handleChange}
              error={formik.touched.documentLink && Boolean(formik.errors.documentLink)}
              helperText={formik.touched.documentLink && formik.errors.documentLink}
            />
            <TextField
              label="Topic"
              name="topic"
              variant="filled"
              fullWidth
              margin="normal"
              value={formik.values.topic}
              onChange={formik.handleChange}
              error={formik.touched.topic && Boolean(formik.errors.topic)}
              helperText={formik.touched.topic && formik.errors.topic}
              inputProps={{ maxLength: 50 }}
            />
            <TextField
              id="filled-select-currency"
              select
              label="Category"
              name="category"
              value={formik.values.category}
              onChange={formik.handleChange}
              error={formik.touched.category && Boolean(formik.errors.category)}
              helperText={formik.touched.category && formik.errors.category}
              variant="filled"
              fullWidth>
              {categories.map((option) => (
                <MenuItem key={option.name} value={option.value}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography ml={1}>{option?.name}</Typography>
                  </Box>
                </MenuItem>
              ))}
            </TextField>
            <TextField
              id="date"
              label="Deadline"
              name="deadline"
              type="date"
              variant="filled"
              value={formik.values.deadline}
              onKeyDown={(event) => {
                event.preventDefault();
              }}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true
              }}
              // inputProps={{
              //   min: `${moment().format('YYYY-MM-DD')}`
              // }}
              error={formik.touched.deadline && Boolean(formik.errors.deadline)}
              helperText={formik.touched.deadline && formik.errors.deadline}
            />
            <FormControl sx={{ m: 1, width: 500 }}>
              <InputLabel
                className={formik.touched.userIds && formik.errors.userIds ? 'Mui-error' : ''}
                id="demo-multiple-chip-label">
                Users
              </InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                fullWidth
                name="userIds"
                input={<FilledInput />}
                value={formik.values.userIds}
                onChange={formik.handleChange}
                MenuProps={{
                  sx: {
                    '& ul': {
                      height: '250px'
                    }
                  }
                }}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={getLables(projectMembers, value)} />
                    ))}
                  </Box>
                )}>
                {projectMembers.map((option) => (
                  <MenuItem key={option.name} value={option.id}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar
                        sx={{
                          background: getBackGroundColor(option?.name)
                        }}
                        src={option?.avatar}
                        alt={option?.name}>
                        {getNameInitials(option?.name)}
                      </Avatar>
                      <Typography ml={1}>{option?.name}</Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText className="Mui-error">
                {formik.touched.userIds && formik.errors.userIds}
              </FormHelperText>
            </FormControl>
            <TextField
              id="filled-select-currency"
              select
              label="Allocated by"
              name="allocatedBy"
              value={formik.values.allocatedBy}
              variant="filled"
              onChange={formik.handleChange}
              error={formik.touched.allocatedBy && Boolean(formik.errors.allocatedBy)}
              helperText={formik.touched.allocatedBy && formik.errors.allocatedBy}>
              {projectMembers.map((option) => (
                <MenuItem key={option.name} value={option.id}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                      src={option?.avatar}
                      alt="name"
                      sx={{
                        width: 20,
                        height: 20,
                        fontSize: '0.625rem',
                        background: getBackGroundColor(option?.name)
                      }}>
                      {getNameInitials(option?.name)}
                    </Avatar>
                    <Typography ml={1}>{option.name}</Typography>
                  </Box>
                </MenuItem>
              ))}
            </TextField>
            <TextField
              id="filled-select-currency"
              select
              label="Status"
              variant="filled"
              fullWidth
              name="status"
              value={formik.values.status}
              onChange={formik.handleChange}
              error={formik.touched.status && Boolean(formik.errors.status)}
              helperText={formik.touched.status && formik.errors.status}>
              {statuses.map((option) => (
                <MenuItem key={option.name} value={option.value}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography ml={1}>{option?.name}</Typography>
                  </Box>
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box sx={{ pr: '20px', textAlign: 'right' }}>
            <Button type="submit" variant="outlined">
              Save
            </Button>
            &nbsp;
            <Button variant="outlined" onClick={() => navigate(-1)}>
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
