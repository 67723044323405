import { useState, useEffect } from 'react';
import useRequestToServer from 'services';

function useAllGlobalProjects() {
  const { requestToServer } = useRequestToServer();
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await requestToServer('project/global-all', '', 'get', true);
        if (response && response.status === 200) {
          let { data } = response.data;
          setProjects(data?.result);
        } else {
          setProjects([]);
        }
      } catch (error) {
        setProjects([]);
      }
    };

    fetchData();
  }, []);

  return { projects };
}

export default useAllGlobalProjects;
