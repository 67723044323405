import { Box, Popover, Card, CardHeader, Avatar } from '@mui/material';

import { useState } from 'react';
import useCommonFunction from 'hooks/useCommonFunction';

const TechSkillsListCell = (props) => {
  const { techSkills = [] } = props;
  const { getNameInitials } = useCommonFunction();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorPlusEl, setAnchorPlusEl] = useState(null);
  const [hoveredUser, setHoveredUser] = useState(null);
  if (!techSkills.length) {
    return '-';
  }
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
      {techSkills.slice(0, 4)?.map((skill, index) => (
        <div key={index}>
          <Avatar
            onMouseEnter={(event) => {
              setAnchorEl(event.currentTarget);
              setHoveredUser(skill);
            }}
            onMouseLeave={() => setAnchorEl(null)}
            src={skill.image}
            alt="name"
            size="xs"
            sx={{
              background: 'transparent',
              border: '2px solid #FFF',
              cursor: 'pointer',
              position: 'relative',
              objectFit: 'contain',

              '&:hover, &:focus': {
                zIndex: '10'
              }
            }}>
            {getNameInitials(skill?.name)}
          </Avatar>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none'
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}>
            <Card sx={{ minWidth: 275 }}>
              <CardHeader
                sx={{
                  padding: '8px 16px'
                }}
                avatar={
                  <Avatar
                    sx={{ background: 'transparent' }}
                    src={hoveredUser?.image}
                    alt="name"
                    size="xs">
                    {getNameInitials(hoveredUser?.name)}
                  </Avatar>
                }
                title={hoveredUser?.name}
              />
            </Card>
          </Popover>
        </div>
      ))}
      {techSkills.length > 4 && (
        <>
          <Avatar
            onMouseEnter={(event) => {
              setAnchorPlusEl(event.currentTarget);
            }}
            onMouseLeave={() => setAnchorPlusEl(null)}
            alt="name"
            size="xs"
            sx={{
              border: '2px solid #FFF',
              cursor: 'pointer',
              position: 'relative',
              ml: -1.25,

              '&:not(:first-of-type)': {
                ml: -1.25
              },

              '&:hover, &:focus': {
                zIndex: '10'
              }
            }}>
            +{techSkills.length - 4}
          </Avatar>
          <Popover
            open={Boolean(anchorPlusEl)}
            anchorEl={anchorPlusEl}
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none'
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}>
            <Card sx={{ minWidth: 275 }}>
              {techSkills.slice(4, techSkills.length).map((techSkill, index) => (
                <CardHeader
                  key={index}
                  sx={{
                    padding: '8px 16px'
                  }}
                  avatar={
                    <Avatar
                      sx={{ background: 'transparent' }}
                      src={techSkill?.image}
                      alt="name"
                      size="xs">
                      {getNameInitials(techSkill?.name)}
                    </Avatar>
                  }
                  title={techSkill?.name}
                />
              ))}
            </Card>
          </Popover>
        </>
      )}
    </Box>
  );
};

export default TechSkillsListCell;
