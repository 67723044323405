import { Box, Button, Divider, Modal, Typography } from '@mui/material';
import { useState } from 'react';
import DocumentsAndListTable from 'components/Table/ProjectExperiment/DocumnetsAndLinks';
import CreateDocument from 'components/Form/ProjectExperiment/CreateDocument';
import AddIcon from '@mui/icons-material/Add';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';

export default function DocumentsAndLinks(props) {
  const { data } = props;
  const [openForm, setOpenForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [row, setRow] = useState();
  const { getIsFeatureEnabled, getProjectRole } = useFeatureFlags();
  const role = getProjectRole(data);
  const isEnabled = getIsFeatureEnabled('DOCUMENTS', role);

  const handleOpen = () => {
    setOpenForm(true);
    setIsEdit(false);
    setRow();
  };
  const handleClose = () => {
    setOpenForm(false);
  };
  return (
    <Box sx={{ margin: 3 }}>
      <Box sx={{ float: 'right', paddingBottom: 2 }} color="primary" px={2}>
        {!openForm && isEnabled && (
          <Button onClick={handleOpen} variant="contained" startIcon={<AddIcon />} color="primary">
            add new document
          </Button>
        )}
        <Modal
          open={openForm}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description">
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              borderRadius: 3,
              outline: 'none'
            }}>
            <Box sx={{ m: 3, textAlign: 'center', position: 'relative' }}>
              <Typography>{isEdit ? 'Edit Document' : 'Create Document'}</Typography>
              <Typography
                sx={{ position: 'absolute', right: 0, top: 0, fontWeight: 500, cursor: 'pointer' }}
                onClick={handleClose}>
                X
              </Typography>
            </Box>
            <Divider />
            <Box
              sx={{
                maxHeight: 550,
                overflowY: 'scroll',
                outline: 'none',
                '&::-webkit-scrollbar': {
                  display: 'none'
                }
              }}>
              <CreateDocument row={row} isEdit={isEdit} shouldClose={handleClose} />
            </Box>
          </Box>
        </Modal>
      </Box>
      <DocumentsAndListTable
        id={data.id}
        isEnabled={isEnabled}
        shouldOpenEditForm={(row) => {
          setOpenForm(true);
          setIsEdit(true);
          setRow(row);
        }}
      />
    </Box>
  );
}
