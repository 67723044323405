import { v4 as uuidv4 } from 'uuid';

export const Variant = {
  ERROR: 'error',
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning'
};

export const Type = {
  ADD_MESSAGE: 'ADD_MESSAGE',
  REMOVE_MESSAGE: 'REMOVE_MESSAGE',
  REMOVE_ALL: 'REMOVE_ALL'
};

export const initialState = {
  notifications: []
};

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case Type.REMOVE_ALL: {
      return {
        ...state,
        notifications: []
      };
    }

    case Type.ADD_MESSAGE: {
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            id: uuidv4(),
            ...payload
          }
        ]
      };
    }
    case Type.REMOVE_MESSAGE: {
      const notifications = state.notifications.filter((notif) => notif?.id !== payload);
      return {
        ...state,
        notifications
      };
    }

    default:
      throw new Error('Incorrect type in NotificationProvider');
  }
};
