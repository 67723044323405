import React from 'react';
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import ProjectListTable from 'components/Table/Project/List';
import { useNavigate } from 'react-router-dom';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { useAuth } from 'providers/AuthProvider';
const ProjectList = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { getIsFeatureEnabled } = useFeatureFlags();
  const isEnabled = getIsFeatureEnabled('PROJECT', user?.loginDetails?.role);
  return (
    <>
      {isEnabled ? (
        <Box sx={{ float: 'right', paddingBottom: 2 }} color="primary" px={2}>
          <Button
            onClick={() => navigate('create')}
            variant="contained"
            startIcon={<AddIcon />}
            color="primary">
            add new project
          </Button>
        </Box>
      ) : (
        ''
      )}

      <ProjectListTable isEnabled={isEnabled} />
    </>
  );
};

export default ProjectList;
