import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import LinkTableCell from './LinkTableCell';
import ActionTableCell from './ActionTableCell';

import useRequestToServer from 'services';
import useNotification from 'hooks/useNotification';
import { useLocation } from 'react-router-dom';
import usePagination from 'hooks/usePagination';

import { Box, InputAdornment, TableSortLabel, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import useDebounce from 'hooks/useDebounce';

function Row(props) {
  const { row, shouldEdit, handleDelete, isEnabled } = props;
  return (
    <React.Fragment>
      <TableRow>
        <TableCell align="left" component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="left">
          <LinkTableCell link={row} />
        </TableCell>
        {isEnabled && (
          <TableCell align="left">
            <ActionTableCell
              row={row}
              shouldEdit={() => shouldEdit(row)}
              handleDelete={handleDelete}
            />
          </TableCell>
        )}
      </TableRow>
    </React.Fragment>
  );
}

const headCells = [
  {
    id: 'name',
    filterable: true,
    label: 'Name'
  },
  {
    id: 'link',
    filterable: false,
    label: 'Link'
  },
  {
    id: 'action',
    filterable: false,
    label: 'Action'
  }
];

const DEFAULT_ORDER = 'asc';
const DEFAULT_ORDER_BY = 'name';

export default function Index(props) {
  const { id, shouldOpenEditForm, isEnabled } = props;
  const { requestToServer } = useRequestToServer();
  const { errorMessage, successMessage } = useNotification();
  const {
    currentPage,
    rowsPerPage,
    order,
    orderBy,
    handleChangePage,
    handleChangeRowsPerPage,
    createSortHandler
  } = usePagination(DEFAULT_ORDER, DEFAULT_ORDER_BY);
  const { state } = useLocation();
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [globalSearch, setGlobalSearch] = useState('');
  const [totalRecords, setTotalRecords] = useState(0);

  const handleRowsPerPageChange = (event) => {
    setPage(0);
    handleChangeRowsPerPage(event);
  };
  const getDocumentList = async (searchKeywords) => {
    let response = await requestToServer(
      `experiment/documents/${id}?recordPerPage=${rowsPerPage}&pageNumber=${currentPage}&orderBy=${orderBy}&orderDir=${order.toUpperCase()}${
        searchKeywords ? '&globalSearch=' + searchKeywords : ''
      }`,
      '',
      'get',
      true
    );
    if (response && response.status === 200) {
      let { data } = response.data;
      setRows(data.result);
      setPage(data.currentPage - 1);
      setTotalRecords(data.totalRecords);
    } else {
      errorMessage(response.data.message);
    }
  };

  useEffect(() => {
    getDocumentList(globalSearch);
  }, [currentPage, rowsPerPage, state, order, orderBy]);

  const debounceFn = useDebounce(getDocumentList, 500);

  const handleChange = (event) => {
    setGlobalSearch(event.target.value);
    debounceFn(event.target.value);
  };

  const handleDelete = async (id) => {
    let response = await requestToServer(`experiment/documents/${id}`, '', 'delete', true);
    if (response && response.status === 200) {
      successMessage('Document deleted successfully.');
      getDocumentList();
    } else {
      errorMessage(response.data.message);
    }
  };

  return (
    <>
      <Box>
        <TextField
          sx={{ mt: 0 }}
          variant="outlined"
          margin="normal"
          name="search"
          placeholder="Search..."
          value={globalSearch}
          onChange={handleChange}
          InputProps={{
            sx: { height: 40, width: 400 },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      </Box>
      <TableContainer component={Paper} elevation={4}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => {
                if (!isEnabled && headCell.id === 'action') {
                  return;
                }
                return (
                  <TableCell
                    key={headCell.id}
                    align="left"
                    sortDirection={orderBy === headCell.id ? order : false}>
                    {headCell.filterable ? (
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}>
                        {headCell.label}
                      </TableSortLabel>
                    ) : (
                      headCell.label
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 ? (
              rows.map((row, index) => (
                <Row
                  key={index}
                  isEnabled={isEnabled}
                  row={row}
                  shouldEdit={shouldOpenEditForm}
                  handleDelete={handleDelete}
                />
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={7}>
                  No record found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 75, 100]}
        component="div"
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </>
  );
}
