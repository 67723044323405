import React, { useState } from 'react';
import { Box, Typography, Modal, Divider } from '@mui/material';

const DescriptionCell = ({ data }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
        <Typography
          onClick={handleOpen}
          sx={{
            width: 300,
            display: 'block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxHeight: 125
          }}
          dangerouslySetInnerHTML={{ __html: data.description }}
        />
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            borderRadius: 3,
            outline: 'none'
          }}>
          <Box sx={{ m: 3, textAlign: 'center', position: 'relative' }}>
            <Typography>Risk description</Typography>
            <Typography
              sx={{ position: 'absolute', right: 0, top: 0, fontWeight: 500, cursor: 'pointer' }}
              onClick={handleClose}>
              X
            </Typography>
          </Box>
          <Divider />
          <Box
            sx={{
              padding: '16px',
              maxHeight: 550,
              overflowY: 'auto',
              outline: 'none',
              '&::-webkit-scrollbar': {
                display: 'none'
              }
            }}>
            <Typography
              sx={{
                width: 500,
                overflow: 'auto'
              }}
              dangerouslySetInnerHTML={{ __html: data.description }}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default DescriptionCell;
