import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { useAuth } from 'providers/AuthProvider';
import { ADMINS } from 'config/constant';
const defaultContext = {
  featureFlags: [],
  getIsFeatureEnabled: () => true
};

const FeatureFlagsContext = createContext(defaultContext);

export const FeatureFlagsProvider = ({ children }) => {
  const { user } = useAuth();

  const featureFlags = useMemo(
    () => [
      {
        feature: ['ALL'],
        roles: ADMINS //Global
      },
      {
        feature: ['MILESTONE', 'DAILY_STANDUP', 'CONTENT_PLAN', 'RISK', 'DOCUMENTS', 'EDIT_RISK'],
        roles: ['Team lead'] //Project based
      },
      {
        feature: ['RISK'],
        roles: ['Developer', 'User'] //GLOBAL and Project based
      }
    ],
    []
  );

  const getIsFeatureEnabled = useCallback(
    (feature, accessUserRole) => {
      const role = user?.loginDetails?.role ? user?.loginDetails?.role : 'User';
      if (ADMINS.includes(role)) {
        return true;
      } else {
        const featureFlag = featureFlags.find(
          (featureName) =>
            featureName.feature.includes(feature) && featureName.roles.includes(accessUserRole)
        );

        if (!featureFlag) {
          return false;
        }
        return true;
      }
    },
    [featureFlags, user]
  );

  const getProjectRole = (data) => {
    const currentUser = user?.loginDetails?.id ? user?.loginDetails?.id : null;
    const { developers, projectLead } = data;
    const devRole = developers.find((_data) => _data.id === currentUser);
    const TLRole = projectLead.find((_data) => _data.id === currentUser);
    return TLRole ? TLRole.role : devRole?.role;
  };

  const context = useMemo(() => {
    return {
      featureFlags,
      getIsFeatureEnabled,
      getProjectRole
    };
  }, [featureFlags, getIsFeatureEnabled]);

  return <FeatureFlagsContext.Provider value={context}>{children}</FeatureFlagsContext.Provider>;
};

export const useFeatureFlags = () => useContext(FeatureFlagsContext);
