import React from 'react';
import { CircularProgress } from '@mui/material';
import { useLoader } from 'providers/LoaderProvider';

const GlobalLoader = () => {
  const { isLoading } = useLoader();

  return isLoading ? (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 9999
      }}>
      <CircularProgress />
    </div>
  ) : null;
};

export default GlobalLoader;
