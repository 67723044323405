import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Typography,
  Chip,
  Select,
  FilledInput,
  FormControl,
  InputLabel,
  FormControlLabel,
  FormHelperText,
  Switch,
  Avatar
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useRequestToServer from 'services';
import useCommonFunction from 'hooks/useCommonFunction';
import useSkills from 'hooks/useSkills';
import usePositions from 'hooks/usePositions';
import { useNavigate } from 'react-router-dom';
import useNotification from 'hooks/useNotification';
import useProjects from 'hooks/useProjects';

export default function EditEmployee(props) {
  const { data, handleClose } = props;
  const navigate = useNavigate();
  const { requestToServer } = useRequestToServer();
  const { skills } = useSkills();
  const { positions } = usePositions();
  const { projects } = useProjects();
  const { getLables, getNameInitials } = useCommonFunction();
  const { successMessage, errorMessage } = useNotification();
  const [skillList, setSkillList] = useState([]);
  const [forms, setForms] = useState([{ projectId: '', hours: 1, error: '' }]);

  useEffect(() => {
    setSkillList(skills);
  }, [skills]);

  useEffect(() => {
    if (data) {
      const values = data.projects.map((item) => ({
        projectId: item.id,
        hours: item.allocatedHours,
        error: ''
      }));
      setForms(values);
    }
  }, [data]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Employee name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    positionId: Yup.string().required('Position is required'),
    skillIds: Yup.array(),
    user_projects: Yup.object().shape({
      projectId: Yup.string(),
      hours: Yup.number()
    }),
    status: Yup.boolean()
  });

  const formik = useFormik({
    initialValues: {
      name: data?.name || '',
      email: data?.email || '',
      positionId: data?.positionId || '',
      skillIds: data?.skills?.map((item) => item.id) || [],
      status: data?.status === 'Active' ? true : false
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      let projects = [...forms];
      let filteredProjects = projects.filter((obj) => {
        delete obj.error;
        if (obj.projectId !== '') {
          return true;
        }
        return false;
      });
      values['user_projects'] = filteredProjects;
      values['positionId'] = values.positionId.toString();
      let response = await requestToServer(`user/${data.id}`, values, 'put', true);
      if (response && response.status === 201) {
        successMessage('Employee updated successfully.');
        handleClose();
        navigate(`/employees/detail/${data.id}`, { state: {} });
      } else {
        errorMessage(response.data.message);
      }
    }
  });

  const handleProjectAllocationChange = (index, event) => {
    const values = [...forms];
    let alreadySelected = false;
    if (event.target.name === 'projectId') {
      alreadySelected = forms.some((obj) => obj.projectId === event.target.value);
    }
    if (alreadySelected) {
      values[index]['error'] = 'This project already allocated. please select another project';
      setForms(values);
    } else {
      values[index]['error'] = '';
      values[index][event.target.name] = event.target.value;
      setForms(values);
    }
  };

  const handleAddForm = () => {
    const hasError = forms.some((obj) => {
      if (!obj.projectId || !obj.hours) {
        obj.error = 'Both field is required!';
        return true;
      }
    });
    const values = [...forms];
    if (hasError) {
      setForms(values);
      return;
    }
    values.push({ projectId: '', hours: 1, error: '' });
    setForms(values);
  };

  const removeProjectAllocation = (index) => {
    const values = [...forms];
    values.splice(index, 1);
    setForms(values);
  };

  return (
    <Box
      sx={{
        padding: 3,
        borderRadius: 2
      }}>
      <Box>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: 400 }
          }}
          onSubmit={formik.handleSubmit}
          autoComplete="off">
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              label="Employee name"
              name="name"
              variant="filled"
              fullWidth
              margin="normal"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              inputProps={{ maxLength: 50 }}
            />
            <TextField
              label="Email"
              name="email"
              variant="filled"
              fullWidth
              margin="normal"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              inputProps={{ maxLength: 50 }}
            />
            <TextField
              id="filled-select-currency"
              select
              label="Position"
              name="positionId"
              variant="filled"
              fullWidth
              value={formik.values.positionId}
              onChange={formik.handleChange}
              error={formik.touched.positionId && Boolean(formik.errors.positionId)}
              helperText={formik.touched.positionId && formik.errors.positionId}>
              {positions.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography ml={1}>{option?.name}</Typography>
                  </Box>
                </MenuItem>
              ))}
            </TextField>
            <FormControl sx={{ m: 1, width: 400 }}>
              <InputLabel
                className={formik.touched.skillIds && formik.errors.skillIds ? 'Mui-error' : ''}
                id="demo-multiple-chip-label">
                Skills
              </InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                fullWidth
                name="skillIds"
                input={<FilledInput />}
                value={formik.values.skillIds}
                onChange={formik.handleChange}
                MenuProps={{
                  sx: {
                    '& ul': {
                      height: '250px'
                    }
                  }
                }}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={getLables(skillList, value)} />
                    ))}
                  </Box>
                )}>
                {skillList.map((option) => (
                  <MenuItem key={option.name} value={option.id}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar
                        sx={{
                          background: 'transparent'
                        }}
                        src={option?.image}
                        alt={option?.name}>
                        {getNameInitials(option?.name)}
                      </Avatar>
                      <Typography ml={1}>{option?.name}</Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText className="Mui-error">
                {formik.touched.skillIds && formik.errors.skillIds}
              </FormHelperText>
            </FormControl>
            <FormControl sx={{ m: 1 }}>
              <InputLabel id="demo-multiple-chip-label">Project allocation</InputLabel>
              <Box mt={5}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {forms.map((form, index) => (
                    <Box key={index}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                          select
                          label="Projects"
                          name="projectId"
                          variant="filled"
                          sx={{ width: '200px !important' }}
                          value={form.projectId}
                          error={!!form.error && !form.projectId}
                          onChange={(event) => handleProjectAllocationChange(index, event)}>
                          {projects.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              <Box
                                sx={{
                                  width: '230px',
                                  display: 'block',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap'
                                }}>
                                <Typography ml={1}>{option?.name}</Typography>
                              </Box>
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          label="Hours/week"
                          name="hours"
                          type="number"
                          InputProps={{ inputProps: { min: 1, max: 40 } }}
                          sx={{ width: '100px !important' }}
                          variant="filled"
                          value={form.hours}
                          error={!!form.error && !form.hours}
                          onChange={(event) => handleProjectAllocationChange(index, event)}
                        />
                        {forms && forms.length > 0 && (
                          <CancelIcon
                            color="error"
                            sx={{ cursor: 'pointer' }}
                            onClick={() => removeProjectAllocation(index)}
                          />
                        )}
                      </Box>
                      <FormHelperText className="Mui-error">
                        {form.error && form.error}
                      </FormHelperText>
                    </Box>
                  ))}
                </Box>
                <Button
                  sx={{ width: 100 }}
                  onClick={handleAddForm}
                  startIcon={<AddIcon />}
                  disabled={projects.length === forms.length}
                  color="primary">
                  add
                </Button>
              </Box>
            </FormControl>
            <FormControl sx={{ m: 2 }}>
              <FormControlLabel
                name="status"
                control={<Switch checked={formik.values.status} />}
                onChange={formik.handleChange}
                label={formik.values.status ? 'Active' : 'Inactive'}
              />
            </FormControl>
          </Box>
          <Box sx={{ pr: '20px', textAlign: 'right' }}>
            <Button type="submit" variant="outlined">
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
